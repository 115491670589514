const Header = () => {
  return (
    <>
      <div className="header pb-8 pt-2 pt-md-6">
      </div>
    </>
  );
};

export default Header;
