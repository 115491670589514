import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

import TransactionsClient from "../../../apis/transactions";
import { formatTime } from "../../../helpers/time";
import dayjs from "dayjs";

const AlarmChartsView = ({ alarm }) => {
  const transactionsClient = new TransactionsClient();

  Chart.register(annotationPlugin);

  const [chartMetrics, setChartMetrics] = useState({
    fill: true,
    labels: [],
    datasets: [],
  });
  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    scales: {},
    plugins: {},
    interaction: {},
  });

  const metricsData = {
    error_rate: {
      label: "% de Erros",
      data_key: "error_rate",
      transform_data: (value) => (value * 100).toFixed(2),
      yLabel: (value) => value + "%",
      tooltip: (value) => Number(value).toFixed(2) + "%",
      threshold: (value) => value,
    },
    error_throughput: {
      // TODO: não sei de onde pegar esses dados
      label: "Quantidade de Erros",
      data_key: "error_count",
      transform_data: (value) => Number(value),
      yLabel: (value) => value,
      tooltip: (value) => value,
      threshold: (value) => value,
    },
    response_time: {
      label: "Tempo de Resposta",
      data_key: "p999",
      transform_data: (value) => Number(value),
      yLabel: (value) => formatTime(value),
      tooltip: (value) => formatTime(value),
      threshold: (value) => value * 1000000,
    },
    throughput: {
      label: "Quantidade de Execuções",
      data_key: "total_count",
      transform_data: (value) => Number(value),
      yLabel: (value) => value,
      tooltip: (value) => value,
      threshold: (value) => value,
    },
  };

  const boxOptions = {
    greater_than: {
      yMin: +alarm.threshold + 1,
    },
    greater_than_or_equal: {
      yMin: +alarm.threshold,
    },
    less_than: {
      yMin: 0,
      yMax: +alarm.threshold - 1,
    },
    less_than_or_equal: {
      yMin: 0,
      yMax: +alarm.threshold,
    },
  };

  const drawHorizontalLine = () => {
    const threshold = metricsData[alarm.metric].threshold(alarm.threshold);

    setChartOptions((options) => ({
      ...options,
      plugins: {
        ...options.plugins,
        annotation: {
          annotations: {
            line: {
              type: "line",
              yMin: threshold,
              yMax: threshold,
              borderColor: "red",
              borderWidth: 1,
            },
            /* box: {
              type: "box",
              backgroundColor: "rgba(255, 99, 132, 0.1)",
              borderWidth: 0,
              ...boxOptions[alarm.operator],
            }, */
          },
        },
      },
    }));
  };

  const getMetricData = (data) => {
    return data[metricsData[alarm.metric].data_key]?.map(
      metricsData[alarm.metric].transform_data
    );
  };

  const loadChart = async () => {
    if (!alarm?.services_names || !alarm?.services_names.length) return;

    //new Date().getTime() - 1000 * 60 * 30;
    const startAt = dayjs().subtract(30, "minute").format('YYYY-MM-DDTHH:mm:ss');
    const endAt = dayjs().format('YYYY-MM-DDTHH:mm:ss');

    const query = {
      startAt: startAt,
      endAt: endAt,
      transaction_name: "*",
      service_name: alarm.services_names[0],
    };
    
    const dataResponse = await transactionsClient.getMetrics(query);
    const data = getMetricData(dataResponse);

    setChartOptions({
      responsive: true,
      interaction: {
        mode: "index",
        intersect: false,
      },
      scales: {
        yLeft: {
          type: "linear",
          position: "left",
          min: 0,
          //max,
          grid: {
            display: false,
          },
          ticks: {
            callback: function (value, index, values) {
              return metricsData[alarm.metric].yLabel(value);
            },
          },
        },
        x: {
          display: true,
          ticks: {
            autoSkip: true,
            maxTicksLimit: 6,
            callback: function (value) {
              return this.getLabelForValue(value.toString())
                .split(/T|\s/)[1]
                .slice(0, 5);
            },
          },
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              if (!tooltipItem.raw) return;
              return metricsData[alarm.metric].tooltip(tooltipItem.raw);
            },
          },
        },
        legend: {
          display: false,
          // position: 'bottom'
        },
      },
    });

    setChartMetrics({
      labels: dataResponse["ts_tz"] || dataResponse["ts"],
      datasets: [
        {
          data,
          borderColor: "green",
          yAxisID: "yLeft",
          pointRadius: 0,
          backgroundColor: "green",
          borderWidth: 1,
          animation: true,
        },
      ],
    });

    drawHorizontalLine();
  };

  useEffect(() => {
    loadChart();
  }, [
    alarm.metric,
    alarm.operator,
    alarm.trigger_times,
    alarm.wait_times,
    alarm.interval_min,
    alarm.threshold,
    alarm.services_names,
  ]);

  return (
    <div>
      <p>{metricsData[alarm.metric].label}</p>
      <Line data={chartMetrics} options={chartOptions} />
    </div>
  );
};

export default AlarmChartsView;
