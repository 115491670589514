import React from 'react';

const Paginate = ({ totalItems, currentPage, perPage, onChange }) => {
    const totalPages = Math.ceil(totalItems / perPage);

    // Garante que a página atual esteja dentro dos limites válidos
    const validCurrentPage = Math.min(Math.max(currentPage, 1), totalPages);

    const renderPageButton = (page) => (
        <button
            key={page}
            className={`pagination__button ${validCurrentPage === page ? 'active' : ''}`}
            onClick={() => onChange(page)}
        >
            {page}
        </button>
    );

    const renderPageButtons = () => {
        const pagesToShow = 2;
        const pages = [];

        for (let i = Math.max(validCurrentPage - pagesToShow, 1); i <= Math.min(validCurrentPage + pagesToShow, totalPages); i++) {
            pages.push(renderPageButton(i));
        }

        return pages;
    };

    return (
        <>
            <div className="pagination">
                {validCurrentPage > 1 && (
                    <button
                        className="pagination__button"
                        onClick={() => onChange(1)}
                    >
                        <i className="fa-solid fa-angles-left"></i>
                    </button>
                )}

                {validCurrentPage !== 1 && (
                    <button
                        className="pagination__button"
                        onClick={() => onChange(validCurrentPage - 1)}
                    >
                    <i className="fa-solid fa-chevron-left"></i>
                    </button>
                )}

                {renderPageButtons()}
                <button
                    className="pagination__button"
                    onClick={() => onChange(validCurrentPage + 1)}
                    disabled={validCurrentPage === totalPages}
                >
                <i className="fa-solid fa-chevron-right"></i>
                </button>
            </div>
        </>
    );
};

export default Paginate;
