const ActionColumn = (props) => {
  const { row } = props;

  return (
    <>
      <div style={{ fontSize: "20px" }}>
        {!props.hideEditButton && (
          <i
            className="fa-solid fa-pen-to-square mr-3"
            style={{ cursor: "pointer" }}
            onClick={() => props.onClickEdit(row)}
          />
        )}
        {!props.hideDetailButton && (
          <i
            className="fa-solid fa-eye mr-3"
            style={{ cursor: "pointer" }}
            onClick={() => props.onClickDetail(row)}
          />
        )}
        {!props.hideDeleteButton && (
          <i
            className="fa-sharp fa-solid fa-trash mr-3"
            style={{ cursor: "pointer", color: "#f75676" }}
            onClick={() => props.onClickDelete(row)}
          />
        )}
      </div>
    </>
  );
};

export default ActionColumn;
