/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Modal,
  CardFooter,
  FormGroup,
  Form,
  Label,
} from "reactstrap";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CopyToClipboard } from "react-copy-to-clipboard";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import { toast } from "react-toastify";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Header from "../../../components/Headers/Header.js";
import ActionColumn from "../../../components/commom/action-column.js";
import MultipleKeyValue from "../../../components/MultipleKeyValue";

import ApikeyClient from "../../../apis/api-key";
import dateHelper from "../../../helpers/date";
import inputHelper from "../../../helpers/debounce";
import {Link, useSearchParams} from "react-router-dom";
import HorizontalSpace from "../../../components/HorizontalSpace";

const ApiKeysView = () => {
  const paginationOptions = {
    sizePerPage: 10,
    showTotal: true,
    hideSizePerPage: true,
  };

  const defaultApikey = {
    id: null,
    name: "",
    api_key: "",
    active: true
  };

  const [apikey, setApikey] = useState(defaultApikey);
  const [apikeys, setApikeys] = useState([]);
  const [secretKey, setSecretKey] = useState("");
  const [totalSize, setTotalSize] = useState(0);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [secretModal, setSecretModal] = useState(false);
  const [modalAction, setModalAction] = useState("create");
  const apikeyClient = new ApikeyClient();

  const schema = yup
    .object()
    .shape({
      name: yup
        .string()
        .required("O nome é obrigatório")
        .max(100, "O nome deve ter no máximo 100 caracteres"),
      active: yup.boolean().required("Dizer se o objeto está ativo é obrigatório"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const setApikeyDetail = (data) => {
    setApikey(data);
    reset(data);
  };

  const toggleModal = (name) => {
    if (name === "form") {
      setFormModal(!formModal);
    } else if (name === "confirm") {
      setConfirmModal(!confirmModal);
    } else if (name === "secret") {
      setSecretModal(!secretModal);
    }
  };

  const closeAllModal = () => {
    setFormModal(false);
    setConfirmModal(false);
  };

  const clearForm = () => {
    setApikeyDetail(defaultApikey);
    reset(defaultApikey);
  };

  const openFormModal = async () => {
    clearForm();
    setModalAction("create");
    toggleModal("form");
  };

  const posSubmit = () => {
    closeAllModal();
    fetchData({ page: 0, sizePerPage: 10 });
    toast.success("Operação realizada com sucesso!");
  };

  const onEdit = async (data) => {
    setModalAction("edit");
    setApikeyDetail(data);
    toggleModal("form");
  };

  const onDelete = async (data) => {
    setApikey(data);
    toggleModal("confirm");
  };

  const onDetail = async (data) => {
    setApikeyDetail(data);
    setModalAction("detail");
    toggleModal("form");
  };

  const posCreate = (data) => {
    setSecretKey(data.api_secret);
    toggleModal("secret");
  };

  const save = async (data) => {
    try {
      if (modalAction === "edit") {
        await apikeyClient.edit(apikey.id, data);
      } else {
        const result = await apikeyClient.create(data);
        posCreate(result);
      }
      posSubmit();
    } catch (error) {
      toast.error("Erro ao inserir registro.");
    }
  };

  const confirmDelete = async () => {
    try {
      await apikeyClient.exclude(apikey.id);
      posSubmit();
    } catch (error) {
      toast.error("Não foi possível deletar o registro.");
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [queryName, setQueryName] = useState("");

  const fetchData = async ({ page, sizePerPage, filter }) => {
    const query = filter ? { query: filter || undefined } : {};
    const response = await apikeyClient.getAll(page, sizePerPage, query);
    setTotalSize(response.total);
    setApikeys(response.data);
  };

  const onTableChange = (_, { page, sizePerPage }) => {
    fetchData({ page, sizePerPage });
  };

  const onFilter = async (e) => {
    setSearchParams({name: e.target.value})
    setQueryName(e.target.value)
    await fetchData({ page: 0, sizePerPage: 10, filter: e.target.value });
  };

  useEffect(() => {
    setQueryName(searchParams.get("name"));
    fetchData({ page: 0, sizePerPage: 10, filter: searchParams.get("name") });
  }, []);

  const columns = [
    {
      dataField: "name",
      text: "Nome",
    },
    {
      dataField: "api_key",
      text: "Key",
    },
    {
      dataField: "active",
      text: "Ativo?",
      formatter: (cell, row) => (
        <span>{row.active ? 'Sim' : 'Não'}</span>
      ),
    },
    {
      dataField: "created_at",
      text: "Data de criação",
      formatter: (cell, row) => (
        <span>{dateHelper.formatterDate(row.created_at)}</span>
      ),
      headerStyle: () => {
        return { width: "1px" };
      },
    },
    {
      dataField: "",
      text: "Ações",
      formatter: (cell, row) => (
        <ActionColumn
          row={row}
          onClickEdit={onEdit}
          onClickDelete={onDelete}
          onClickDetail={onDetail}
        />
      ),
      headerStyle: () => {
        return { width: "1px" };
      },
    },
  ];

  return (
    <>
      <Row>
        <Col sm={12}>
          <h1><i className="fa-solid fa-key"></i> Api Keys</h1>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Card className="shadow">
            <CardHeader className="">
              <Row>
                <Col md="10">
                  <input
                      id="filter"
                      className="form-control"
                      placeholder="Buscar"
                      style={{width: "200px"}}
                      onChange={onFilter}
                      value={queryName}
                  />
                </Col>
                <Col md="2" className="text-right">
                  <Link
                      className="btn btn-outline-primary"
                      type="button"
                      to={"/administration/api_keys/new"}
                  >
                      <span className="btn-inner--icon">
                        <i className="fa-solid fa-plus" />
                      </span>
                      <span className="btn-inner--text">Novo</span>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <BootstrapTable
                keyField="id"
                data={apikeys}
                columns={columns}
                pagination={paginationFactory({
                  ...paginationOptions,
                  totalSize,
                })}
                onTableChange={onTableChange}
                remote={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal
        className="modal-dialog-centered"
        size="xl"
        isOpen={formModal}
        toggle={() => toggleModal("form")}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader>
              <h3 className="mb-0">
                {modalAction === "create"
                  ? "Nova"
                  : modalAction === "detail"
                  ? "Visualizar"
                  : "Editar"}{" "}
                Api Key
              </h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="12">
                  <Form onSubmit={handleSubmit(save)} id="myform">
                    <Row>
                      {/* Name */}
                      <Col md="6">
                        <FormGroup>
                          <Label for="">Nome</Label>
                          <input
                            id="name"
                            className="form-control"
                            placeholder="Nome"
                            readOnly={modalAction === "detail"}
                            {...register("name")}
                          />
                          <p className="error-form-message">
                            {errors.name?.message}
                          </p>
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <Label for="">Active?</Label>
                          <input
                            id="active"
                            className="form-control"
                            placeholder="Active?"
                            readOnly={modalAction === "detail"}
                            type="checkbox"
                            {...register("active")}
                          />
                          <p className="error-form-message">
                            {errors.active?.message}
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-right">
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => {
                      toggleModal("form");
                    }}
                  >
                    Cancelar
                  </Button>
                  {modalAction !== "detail" && (
                    <Button color="primary" type="submit" form="myform">
                      Salvar
                    </Button>
                  )}
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </div>
      </Modal>

      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={confirmModal}
        toggle={() => toggleModal("confirm")}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader>
              <h3 className="mb-0">Confirmação</h3>
            </CardHeader>
            <CardBody>Deseja mesmo deletar essa api key?</CardBody>
            <CardFooter className="text-right">
              <Button
                color="secondary"
                type="button"
                onClick={() => {
                  toggleModal("confirm");
                }}
              >
                Cancelar
              </Button>
              <Button color="danger" type="button" onClick={confirmDelete}>
                Deletar
              </Button>
            </CardFooter>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default ApiKeysView;
