/* eslint-disable react-hooks/exhaustive-deps */
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    ButtonGroup, Button,
} from "reactstrap";
import { useState, useEffect } from "react";

import IncidentClient from "../../../apis/incidents";
import {Link, useSearchParams} from "react-router-dom";
import dayjs from "dayjs";
import {toast} from "react-toastify";
import HorizontalSpace from "../../../components/HorizontalSpace";

const OPERATOR_ENUM = {
    // equal: 0,
    // not_equal: 1,
    greater_than: '>',
    greater_than_or_equal: '>=',
    less_than: '<',
    less_than_or_equal: '<='
}

const IncidentIndexView = () => {
    const [incidents, setIncidents] = useState([]);
    const incidentClient = new IncidentClient();

    const fetchData = async ({ page, sizePerPage, filter }) => {
        const query = filter ? { query: filter || undefined } : {};
        return incidentClient.getAll(page, sizePerPage, query)
            .then(response => setIncidents(response.data));
    };

    const get_diff_time = (date, left_time) => {
        const MINUTES_IN_HOUR = 60;
        const MINUTES_IN_DAY = 1440;
        const MINUTES_IN_WEEK = 10080;

        let diff = left_time.diff(dayjs(new Date(date)), 'minutes');

        if (diff === 0) {
            return 'Agora';
        }

        let result = '';

        if (diff >= MINUTES_IN_WEEK) {
            const weeks = Math.floor(diff / MINUTES_IN_WEEK);
            result += `${weeks} semana${weeks !== 1 ? 's' : ''} `;
            diff -= weeks * MINUTES_IN_WEEK;
        }

        if (diff >= MINUTES_IN_DAY) {
            const days = Math.floor(diff / MINUTES_IN_DAY);
            result += `${days} dia${days !== 1 ? 's' : ''} `;
            diff -= days * MINUTES_IN_DAY;
        }

        if (diff >= MINUTES_IN_HOUR) {
            const hours = Math.floor(diff / MINUTES_IN_HOUR);
            result += `${hours} hora${hours !== 1 ? 's' : ''} `;
            diff -= hours * MINUTES_IN_HOUR;
        }

        if (diff > 0) {
            result += `${diff} minuto${diff !== 1 ? 's' : ''}`;
        }

        return result.trim(); // Remove espaços extras no final, se houver.
    };


    const buildMetric = (alarm) => {
        let label = "";
        let sufix = '';

        switch (alarm.metric) {
            case "error_rate":
                label = `Taxa de Erros`;
                sufix = '%';
                break
            case "response_time":
                label = "Tempo de Resposta";
                sufix = 'ms';
                break
            case "error_throughput":
                label = "Quantidade de Erros";
                break
            case "throughput":
                label = "Quantidade de Execuções";
                break
        }

        label += ` ${OPERATOR_ENUM[alarm.operator]} ${alarm.threshold}${sufix} por ${alarm.wait_times} vezes em intervalo de ${alarm.interval_min} minuto(s)`;

        return label;
    }

    const handleAccept = (incident_id) => {
        incidentClient.accept(incident_id).then(() => {
            toast.success("Incidente aceito com sucesso!")
            fetchData({ page: 0, sizePerPage: 20 });
        })
    }

    const fetchRecursive = () => {
        fetchData({ page: 0, sizePerPage: 20 })
            .then(response => {
                setTimeout(() => {
                    fetchRecursive()
                }, 1000 * 30);
            })
    }

    useEffect(() => {
        fetchRecursive()
    }, []);

    return (
        <>
            <Row>
                <Col sm={12}>
                    <h1><i className={"fa-solid fa-triangle-exclamation"}></i> Incidentes</h1>
                </Col>
            </Row>

            <Row>
                <div className="col">
                    <Card className="shadow">
                        {/*<CardHeader className="">*/}
                        {/*    <Row>*/}
                        {/*        <Col md="12">*/}
                        {/*            <input*/}
                        {/*                id="filter"*/}
                        {/*                className="form-control"*/}
                        {/*                placeholder="Buscar"*/}
                        {/*                style={{width: "400px"}}*/}
                        {/*                onChange={onFilter}*/}
                        {/*                value={queryName}*/}
                        {/*            />*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*</CardHeader>*/}
                        <CardBody style={{overflowY: 'scroll'}}>
                            <table className={"table default-table"}>
                                <thead>
                                <tr>
                                    <th style={{width: 0}}></th>
                                    <th style={{width: 0}}>Alarme</th>
                                    <th>Serviço</th>
                                    <th style={{width: 0}}>Usuário</th>
                                    <th style={{width: 0}}>Duração</th>
                                    <th style={{width: 0}}>Aberto em</th>
                                    <th style={{width: 0}}>Finalizado Em</th>
                                    <th style={{width: 0}}></th>
                                </tr>
                                </thead>

                                <tbody>
                                {incidents.map((incident, index) => (
                                    <tr key={index}>
                                        <td style={{textAlign: 'center', width: 0}}>
                                            <span>
                                                {incident.closed_at && <i className="fa-solid fa-check-circle"
                                                                          style={{color: 'green'}}></i>}
                                                {!incident.closed_at && <i className="fa-solid fa-exclamation-triangle"
                                                                           style={{color: 'red'}}></i>}
                                            </span>
                                        </td>

                                        <td style={{width: 0}}>
                                            <Link
                                                to={`/alarms/${incident.alarm.id}/edit`}>{incident.alarm.name}</Link>
                                        </td>

                                        <td>
                                            <div><Link
                                                to={`/services?serviceName=${encodeURIComponent(incident.service.name)}`}>{incident.service.name}</Link>
                                            </div>
                                            <div><small>{buildMetric(incident.alarm, incident)}</small></div>
                                        </td>

                                        <td style={{width: 0}}>
                                            {incident.user && (<span>{incident.user?.email}</span>)}
                                            {(!incident.user && !incident.closed_at) && (<Button className={"btn-small"}
                                                                                                 onClick={() => handleAccept(incident.id)}>
                                                <i className="fa-solid fa-thumbs-up"></i>
                                                Aceitar!
                                            </Button>)}
                                        </td>

                                        <td style={{width: 0}}>
                                            {!incident.closed_at && get_diff_time(incident.opened_at, dayjs(new Date()))}
                                            {incident.closed_at && get_diff_time(incident.opened_at, dayjs(incident.closed_at))}
                                        </td>

                                        <td style={{width: 0}}>
                                            <span title={new Date(incident.opened_at).toLocaleString()}>
                                                {new Date(incident.opened_at).toLocaleString()}
                                            </span>
                                        </td>

                                        <td style={{width: 0}}>
                                            <span>
                                                {incident.closed_at && new Date(incident.closed_at).toLocaleString()}
                                                {!incident.closed_at && "Aberto"}
                                            </span>
                                        </td>

                                        <td style={{width: 0}}>
                                            <ButtonGroup>
                                            </ButtonGroup>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </div>
            </Row>
        </>
    );
};

export default IncidentIndexView;
