/* eslint-disable import/no-anonymous-default-export */

const formatterDate = (date) => {
  if (!date) return "";
  const [dateOnly, time] = date.split("T");
  return `${dateOnly.split("-").reverse().join("/")} ${time
    .split(".")[0]
    .replace("Z", "")}`;
};

export default { formatterDate };
