/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { useState, useEffect } from "react";

import BillingApi from "../../../apis/billing-api";
import dayjs from "dayjs";

const formatCurrency = (valor) => {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor);
};

const BillingIndex = () => {
  const [billings, setBillings] = useState([]);
  const [totalSize, setTotalSize] = useState(0);
  const billingClient = new BillingApi();

  const fetchData = async ({ page, sizePerPage, filter }) => {
    const query = filter ? { query: filter || undefined } : {};
    const response = await billingClient.getAll(page, sizePerPage, query);
    setTotalSize(response.total);
    setBillings(response.data);
  };


  useEffect(() => {
    fetchData({ page: 0, sizePerPage: 12 });
  }, []);

  return (
    <>
      <Row>
        <Col sm={12}>
          <h1><i className="fa-solid fa-money-bills"></i> Cobranças</h1>
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={6}>
          <Card className="shadow">
            <CardBody>
              <table className={"table default-table"}>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th width={'100px'}>Subtotal</th>
                    <th width={'100px'}>Desconto</th>
                    <th width={'100px'}>Total</th>
                  </tr>
                </thead>

                <tbody>
                  {billings.map((billing, index) => (
                    <tr key={index}>
                      <td>{dayjs(billing.billing_month).format('MMMM')} de {dayjs(billing.billing_month).format('YYYY')}</td>
                      <td width={'100px'}>{formatCurrency(billing.amount)}</td>
                      <td width={'100px'}>{formatCurrency(billing.discount)}</td>
                      <td width={'100px'}>{formatCurrency(billing.total_cost)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row>

    </>
  );
};

export default BillingIndex;
