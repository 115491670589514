/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import AlarmClient from "../../../apis/alarms";
import { Link, useParams } from "react-router-dom";
import ServiceClient from "../../../apis/service";
import ChannelClient from "../../../apis/channels";
import TransactionsClient from "../../../apis/transactions";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import HorizontalSpace from "../../../components/HorizontalSpace";

import AlarmChartsView from "./charts";

const AlarmFormView = () => {
  const { id: alarmId } = useParams();
  let id = alarmId;

  const alarmClient = new AlarmClient();
  const serviceClient = new ServiceClient();
  const channelsClient = new ChannelClient();
  const transactionsClient = new TransactionsClient();

  const metricsOptions = [
    { value: "error_rate", label: "% de Erros" },
    { value: "error_throughput", label: "Quantidade de Erros" },
    { value: "response_time", label: "Tempo de Resposta" },
    { value: "throughput", label: "Quantidade de Execuções" },
  ];

  const operatorOptions = [
    { value: "greater_than", label: "Maior que" },
    { value: "greater_than_or_equal", label: "Maior ou igual a" },
    { value: "less_than", label: "Menor que" },
    { value: "less_than_or_equal", label: "Menor ou igual a" },
  ];

  const [alarm, setAlarm] = useState({
    name: "",
    metric: "error_rate",
    operator: "greater_than_or_equal",
    trigger_times: 3,
    wait_times: 3,
    interval_min: 1,
    threshold: 1,
    channels_ids: [],
    services_names: [],
  });
  const [serviceOptions, setServiceOptions] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [channelOptions, setChannelOptions] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState(metricsOptions[0]);
  const [selectedOperator, setSelectedOperator] = useState(operatorOptions[3]);
  const [metricUnit, setMetricUnit] = useState("%");

  const handleMetricUnit = (metric) => {
    switch (metric) {
      case "error_rate":
        setMetricUnit("%");
        break;
      case "response_time":
        setMetricUnit("ms");
        break;
      default:
        setMetricUnit("");
    }
  };

  const handleSelectOperator = (operator) => {
    setAlarm({ ...alarm, operator: operator.value });
    setSelectedOperator(operator);
  };

  const handleSelectService = (data) => {
    setSelectedServices(data);
    setAlarm({
      ...alarm,
      services_names: data.map((service) => service.label),
    });
  };

  const handleSelectChannel = (data) => {
    setSelectedChannels(data);
    setAlarm({ ...alarm, channels_ids: data.map((channel) => channel.value) });
  };

  const handleSelectMetric = (option) => {
    setSelectedMetric(option);
    handleMetricUnit(option.value);
    setAlarm({ ...alarm, metric: option.value });
  };

  const save = async () => {
    try {
      if (id) {
        await alarmClient.edit(id, alarm).then(() => {
          toast.success("Atualizado com sucesso!");
        });
      } else {
        const result = await alarmClient.create(alarm);

        if (result) {
          toast.success("Criado com sucesso!");
          id = result.id;
          // window.location.href = `/alarms/${result.id}/edit`;
        }
      }
    } catch (error) {
      toast.error("Erro ao inserir registro.");
    }
  };

  const startForm = (currentAlarm) => {
    setSelectedMetric(
      metricsOptions.find((metric) => metric.value === currentAlarm.metric)
    );
    setSelectedOperator(
      operatorOptions.find(
        (operator) => operator.value === currentAlarm.operator
      )
    );
    serviceClient.getAll(0, 1000).then(({ data }) => {
      setServiceOptions(
        data.map((service) => ({ label: service.name, value: service.name }))
      );
      setSelectedServices(
        currentAlarm.services_names.map((service) => ({
          label: service,
          value: service,
        }))
      );
    });
    channelsClient.getAll().then(({ data }) => {
      setChannelOptions(
        data.map((channel) => ({ value: channel.id, label: channel.name }))
      );
      setSelectedChannels(
        data
          .filter((channel) => currentAlarm.channels_ids.includes(channel.id))
          .map((channel) => ({ value: channel.id, label: channel.name }))
      );
    });
  };

  useEffect(() => {
    if (id) {
      alarmClient.findOne(id).then((data) => {
        setAlarm(data);
        startForm(data);
        handleMetricUnit(data.metric);
      });
    } else {
      startForm(alarm);
    }
  }, []);

  return (
    <>
      <Row>
        <Col sm={9}>
          <Card className="shadow">
            <CardHeader className="">
              <Row>
                <Col md="10">
                  <h3 className="mb-0">Alarme</h3>
                </Col>
                <Col md="2" className="text-right">
                  <Link className="btn" type="button" to={"/monitoring/alarms"}>
                    <span className="btn-inner--icon">
                      <i className="fa-solid fa-arrow-left" />
                    </span>

                    <span className="btn-inner--text">Voltar</span>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FormGroup onSubmit={save}>
                <Row>
                  <Col sm="6">
                    <Row>
                      <Col sm="12">
                        <Label for="name">Nome*</Label>
                        <Input
                          name={"name"}
                          value={alarm.name}
                          onChange={(e) =>
                            setAlarm({ ...alarm, name: e.target.value })
                          }
                          required={true}
                          minLength={4}
                        />
                      </Col>
                    </Row>

                    <HorizontalSpace />

                    <Row>
                      <Col sm="12">
                        <Label>Serviços</Label>
                        <CreatableSelect
                          isMulti
                          value={selectedServices}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={serviceOptions}
                          onChange={handleSelectService}
                          placeholder="Selecione ou digite o nome do serviço"
                        />
                      </Col>
                    </Row>

                    <HorizontalSpace />

                    <Row>
                      <Col sm="12">
                        <Label>Canais</Label>
                        <Select
                          isMulti
                          value={selectedChannels}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          options={channelOptions}
                          onChange={handleSelectChannel}
                          placeholder="Selecione um canal"
                        />
                      </Col>
                    </Row>

                    <HorizontalSpace />

                    <Row>
                      <Col sm="6">
                        <Label>Métrica</Label>
                        <Select
                          value={selectedMetric}
                          options={metricsOptions}
                          onChange={handleSelectMetric}
                        />
                      </Col>

                      <Col sm="6">
                        <Label>Condição</Label>
                        <Select
                          value={selectedOperator}
                          options={operatorOptions}
                          onChange={handleSelectOperator}
                        />
                      </Col>
                    </Row>

                    <HorizontalSpace />

                    <Row>
                      <Col sm="3">
                        <Label>Gatilho</Label>
                        <FormGroup>
                          <InputGroup className="mb-4">
                            <Input
                              type={"number"}
                              value={alarm.threshold}
                              onChange={(e) =>
                                setAlarm({ ...alarm, threshold: e.target.value })
                              }
                            />
                            {metricUnit && (
                              <InputGroupAddon addonType="append">
                                <InputGroupText>{metricUnit}</InputGroupText>
                              </InputGroupAddon>
                            )}
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <Label>Intervalo</Label>
                        <FormGroup>
                          <InputGroup className="mb-2">
                            <Input
                              type={"number"}
                              min={1}
                              max={5}
                              value={alarm.interval_min}
                              onChange={(e) =>
                                setAlarm({
                                  ...alarm,
                                  interval_min: e.target.value,
                                })
                              }
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText>Min</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </FormGroup>
                      </Col>

                      <Col sm="3">
                        <Label
                          title={
                            "Quantidade de vezes que o gatilho deve disparar no Período analisado"
                          }
                        >
                          Recorrência
                        </Label>
                        <Input
                          type={"number"}
                          min={1}
                          value={alarm.trigger_times}
                          onChange={(e) =>
                            setAlarm({
                              ...alarm,
                              trigger_times: e.target.value,
                            })
                          }
                        />
                      </Col>

                      <Col sm="3">
                        <Label title={"Quantidade de intervalos analisados"}>
                          Período
                        </Label>
                        <Input
                          type={"number"}
                          value={alarm.wait_times}
                          min={1}
                          onChange={(e) =>
                            setAlarm({ ...alarm, wait_times: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                  </Col>


                  <Col sm={6}>
                    <div style={{background: "#fff", margin: "0", padding: "15px", border: "1px solid #cad1d7", borderRadius: "8px"}}>
                        <AlarmChartsView alarm={alarm} />
                    </div>
                  </Col>
                </Row>

                <HorizontalSpace />

                <Row>
                  <Col style={{ textAlign: "right" }}>
                    <Button color="primary" type="submit" onClick={save}>
                      <span className="btn-inner--icon">
                        <i className="fa-solid fa-floppy-disk" />
                      </span>
                      <span className="btn-inner--text">Salvar</span>
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </CardBody>

            <HorizontalSpace />
          </Card>
        </Col>

        <Col sm={3} className={"form-text-helper"}>
          <h2>Guia de Configuração de Alarmes</h2>

          <section>
            <h3>Serviços</h3>
            <p>Defina quais serviços serão monitorados.</p>
            <p>
              Você pode usar <strong>*</strong> como wildcard para selecionar
              vários serviços.
            </p>
            <p>Exemplos:</p>
            <ul>
              <li>
                <strong>*</strong> seleciona <strong>todos</strong> os serviços
              </li>
              <li>
                <strong>API*</strong> seleciona todos os serviços que{" "}
                <strong>iniciam</strong> com <strong>API</strong>
              </li>
              <li>
                <strong>*API</strong> seleciona todos os serviços que{" "}
                <strong>terminam</strong> com <strong>API</strong>
              </li>
              <li>
                <strong>*API*</strong> seleciona todos os serviços que{" "}
                <strong>contêm</strong> <strong>API</strong> no nome
              </li>
            </ul>
          </section>

          <section>
            <h3>Canais</h3>
            <p>
              Especifique os destinos para onde as notificações serão enviadas.
            </p>
          </section>

          <section>
            <h3>Métrica</h3>
            <p>Escolha qual métrica da aplicação será monitorada:</p>
            <ul>
              <li>
                <strong>% de erros:</strong> proporção de erros da aplicação
              </li>
              <li>
                <strong>Quantidade de erros:</strong> quantidade absoluta de
                erros
              </li>
              <li>
                <strong>Tempo de Resposta:</strong> valor absoluto da latência
                da aplicação
              </li>
            </ul>
          </section>

          <section>
            <h3>Condição</h3>
            <p>Define como a métrica será monitorada.</p>
          </section>

          <section>
            <h3>Gatilho</h3>
            <p>
              Especifica o valor que, quando combinado com a condição e a
              métrica, dispara o alarme.
            </p>
          </section>

          <section>
            <h3>Intervalo</h3>
            <p>Determina quantos minutos deve durar cada rodada de análise.</p>
          </section>

          <section>
            <h3>Recorrência</h3>
            <p>
              Define a quantidade de vezes que o gatilho deve disparar no
              intervalo analisado.
            </p>
          </section>

          <section>
            <h3>Período</h3>
            <p>Indica a quantidade de intervalos analisados.</p>
          </section>
        </Col>
      </Row>
    </>
  );
};

export default AlarmFormView;
