/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    Modal,
    CardFooter,
    FormGroup,
    Form,
    Label, InputGroup, Input, DropdownToggle, DropdownMenu, DropdownItem, Dropdown,
} from "reactstrap";
import {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";


import ChannelClient from "../../../apis/channels";
import {Link, useParams} from "react-router-dom";
import HorizontalSpace from "../../../components/HorizontalSpace";

function validarEmail(email) {
    // Expressão regular para validar o formato do e-mail
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Testa se o e-mail corresponde à expressão regular
    return regex.test(email);
}

function removerElementoPorIndice(arr, indice) {
    // Verifica se o índice está dentro dos limites do array
    if (indice >= 0 && indice < arr.length) {
        // Cria um novo array excluindo o elemento no índice especificado
        return [...arr.slice(0, indice), ...arr.slice(indice + 1)];
    } else {
        // Retorna o array original se o índice for inválido
        return arr;
    }
}

const ChannelFormView = () => {
    const { id } = useParams();

    const channelClient = new ChannelClient();

    const [name, setName] = useState("");

    const [selectedOption, setSelectedOption] = useState('email');
    const [selectedOptionOpen, setSelectedOptionOpen] = useState(false);
    const [email, setEmail] = useState("");
    const [emailSettings, setEmailSettings] = useState({
        to: []
    });
    const [webhookURL, setWebhookURL] = useState("");
    const [webhookTemplate, setWebhookTemplate] = useState(`{{datetime}} {{name}} {{status}} {{message}}`);

    const toggleDropdown = () => {
        setSelectedOptionOpen(!selectedOptionOpen);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleChangeName = (e) => setName(e.target.value);

    // const emailChannelSchema = yup.object({
    //     name: yup.string().required('O nome é obrigatório'),
    //     channel_type: yup.string().equals(['email'], 'Tipo de canal inválido'),
    //     settings: yup.object({
    //         to: yup.array().of(yup.string().email('Formato de e-mail inválido')).required('Destinatários são obrigatórios'),
    //     }).required('Configurações são obrigatórias'),
    // });
    //
    // const webhookChannelSchema = yup.object({
    //     name: yup.string().required('O nome é obrigatório'),
    //     channel_type: yup.string().equals(['webhook'], 'Tipo de canal inválido'),
    //     settings: yup.object({
    //         url: yup.string().url('URL inválida').required('A URL é obrigatória'),
    //         template: yup.string().required('O template é obrigatório'),
    //     }).required('Configurações são obrigatórias'),
    // });

    // const {
    //     formState: { errors }
    // } = useForm({
    //     resolver: yupResolver(schema),
    // });

    const save = async () => {
        try {
            const settings = {};
            if(selectedOption === 'email') {
                settings.to = emailSettings.to;
            }
            if(selectedOption === 'webhook') {
                settings.url = webhookURL;
                settings.template = webhookTemplate;
            }

            if(id) {
                await channelClient.edit(id, {
                    name,
                    channel_type: selectedOption,
                    settings
                }).then(() => {
                    toast.success("Atualizado com sucesso!");
                })
            } else {
                const result = await channelClient.create({
                    name,
                    channel_type: selectedOption,
                    settings
                });

                if(result) {
                    window.location.href = `/monitoring/channels/${result.id}/edit`;
                }
            }
        } catch (error) {
            toast.error("Erro ao inserir registro.");
        }
    };

    const handleChangeEmail = (e) => {
        setEmail(e.target.value);
    };

    const addEmail = () => {
        if(!validarEmail(email)) {
            alert("E-mail inválido");
        } else {
            setEmailSettings({
                ...emailSettings,
                to: new Array(...new Set(emailSettings.to.concat([email])))
            })
            setEmail("")
        }
    }

    const removeEmail = (index) => {
        setEmailSettings({
            ...emailSettings,
            to: removerElementoPorIndice(emailSettings.to, index)
        })
    }

    const renderEmailSettings = () => {
        return (
            <>
                <Row>
                    <Col sm={4}>
                        <Input value={email} onChange={handleChangeEmail}/>
                    </Col>
                    <Col sm={2}>
                        { validarEmail(email) && <Button onClick={addEmail}>Adicionar</Button> }
                    </Col>
                </Row>

                <HorizontalSpace />

                <Row>
                    <Col sm={12}>
                        <ul>
                            {emailSettings.to.map((to, index) => (
                                <li key={index} className={"row"}>
                                    {to}<Button style={{marginLeft: '20px'}} className={"btn-danger btn-small"} onClick={() => removeEmail(index)}>Remover</Button>
                                </li>
                            ))}
                        </ul>
                    </Col>
                </Row>
            </>
        )
    }

    useEffect(() => {
        if (id) {
            channelClient.findOne(id).then((data) => {
                setName(data.name);
                setSelectedOption(data.channel_type);
                if (data.channel_type === 'email') {
                    setEmailSettings(data.settings);
                }
                if (data.channel_type === 'webhook') {
                    setWebhookURL(data.settings.url);
                    setWebhookTemplate(data.settings.template);
                }
            })
        }
    }, []);

    const renderWebhookSettings = () => {
        return (
            <>
                <Row>
                    <Col sm={12}>
                        <label>Webhook URL</label>
                        <Input value={webhookURL} onChange={(e) => setWebhookURL(e.target.value)}/>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <label>Template</label>
                        <textarea defaultValue={webhookTemplate} style={{width: '100%', height: '200px'}} onChange={(e) => setWebhookTemplate(e.target.value)} />
                    </Col>
                </Row>
            </>
        )
    }

    return (
        <>
            <Row>
                <Col sm={12}>
                    <Card className="shadow">
                        <CardHeader className="">
                            <Row>
                                <Col md="10">
                                    <h3 className="mb-0">Novo Canal</h3>
                                </Col>
                                <Col md="2" className="text-right">
                                    <Link
                                        className="btn"
                                        type="button"
                                        to={"/monitoring/channels"}
                                    >
                                        <span className="btn-inner--icon">
                                          <i className="fa-solid fa-arrow-left" />
                                        </span>

                                        <span className="btn-inner--text">Voltar</span>
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <FormGroup onSubmit={save}>
                                <Row>
                                    <Col sm={12}>
                                        <Label for="name">Nome*</Label>
                                        <Input name={"name"} value={name} onChange={handleChangeName} required={true} minLength={4} />
                                        {/*<p className="error-form-message">*/}
                                        {/*    {errors.name?.message}*/}
                                        {/*</p>*/}
                                    </Col>
                                </Row>

                                <HorizontalSpace />

                                <Row>
                                    <Col sm={12}>
                                        <Dropdown isOpen={selectedOptionOpen} toggle={toggleDropdown} style={{marginTop: '2px'}} id={"dropdown-accounts"}>
                                            <DropdownToggle caret>
                                                {selectedOption}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem className="noti-title" header tag="div" key={'email'}>
                                                    <Button className={"btn-link btn-expand"} onClick={() => setSelectedOption('email')}>
                                                        e-mail
                                                    </Button>
                                                </DropdownItem>
                                                <DropdownItem className="noti-title" header tag="div" key={'webhook'}>
                                                    <Button className={"btn-link btn-expand"} onClick={() => setSelectedOption('webhook')}>
                                                        webhook
                                                    </Button>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </Col>
                                </Row>

                                <HorizontalSpace />

                                <Row>
                                    <Col sm={12}>
                                        {selectedOption === "email" && renderEmailSettings()}
                                        {selectedOption === "webhook" && renderWebhookSettings()}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col style={{textAlign: "right"}}>
                                        <Button
                                            color="primary"
                                            type="submit"
                                            onClick={save}
                                        >
                        <span className="btn-inner--icon">
                          <i className="fa-solid fa-floppy-disk" />
                        </span>
                                            <span className="btn-inner--text">Salvar</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ChannelFormView;
