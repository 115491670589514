/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Modal,
  CardFooter,
  FormGroup,
  Form,
  Label, InputGroup, Input,
} from "reactstrap";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { CopyToClipboard } from "react-copy-to-clipboard";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import { toast } from "react-toastify";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Header from "../../../components/Headers/Header.js";
import ActionColumn from "../../../components/commom/action-column.js";
import MultipleKeyValue from "../../../components/MultipleKeyValue";

import ApikeyClient from "../../../apis/api-key";
import dateHelper from "../../../helpers/date";
import inputHelper from "../../../helpers/debounce";
import {Link, redirect} from "react-router-dom";

const ApiKeysNewView = () => {
  const apikeyClient = new ApikeyClient();

  const [name, setName] = useState("");

  const handleChangeName = (e) => setName(e.target.value);

  const schema = yup
    .object()
    .shape({
      name: yup
        .string()
        .required("O nome é obrigatório")
        .max(100, "O nome deve ter no máximo 100 caracteres"),
      active: yup.boolean().required("Dizer se o objeto está ativo é obrigatório"),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const save = async () => {
    try {
      const result = await apikeyClient.create({
        name
      });

      if(result) {
        window.location.href = `/administration/api_keys?name=${name}`
      }

    } catch (error) {
      toast.error("Erro ao inserir registro.");
    }
  };

  return (
    <>
      <Row>
        <Col sm={12}>
          <Card className="shadow">
            <CardHeader className="">
              <Row>
                <Col md="10">
                  <h3 className="mb-0">Api Keys</h3>
                </Col>
                <Col md="2" className="text-right">
                  <Link
                      className="btn"
                      type="button"
                      to={"/administration/api_keys"}
                  >
                        <span className="btn-inner--icon">
                          <i className="fa-solid fa-arrow-left" />
                        </span>
                    <span className="btn-inner--text">Voltar</span>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FormGroup onSubmit={save}>
                <Row>
                  <Col sm={12}>
                    <Label for="name">Nome*</Label>
                    <Input name={"name"} value={name} onChange={handleChangeName} required={true} minLength={4} />
                    <p className="error-form-message">
                      {errors.name?.message}
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col style={{textAlign: "right"}}>
                    <Button
                        color="primary"
                        type="submit"
                        onClick={save}
                    >
                        <span className="btn-inner--icon">
                          <i className="fa-solid fa-floppy-disk" />
                        </span>
                      <span className="btn-inner--text">Salvar</span>
                    </Button>
                  </Col>
                </Row>
              </FormGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default ApiKeysNewView;
