import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
} from "reactstrap";
import UserClient from "../../../apis/users";
import { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import MeClient from "../../../apis/me";

const userClient = new UserClient();
const meClient = new MeClient();

const PERMISSION_LABEL = {
  viewer: "Visualizador",
  manager: "Gerente",
  admin: "Administrador",
};

const UserIndex = () => {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState("");
  const [currentUserAccount, setCurrentUserAccount] = useState();
  const [newEmail, setNewEmail] = useState("");

  const permissionsOptions = [
    { value: "viewer", label: PERMISSION_LABEL.viewer },
    { value: "manager", label: PERMISSION_LABEL.manager },
    { value: "admin", label: PERMISSION_LABEL.admin },
  ];

  const activeOptions = [
    { value: true, label: "Ativo" },
    { value: false, label: "Desativado" },
  ];
  const [currentPermission, setCurrentPermission] = useState(
    permissionsOptions[0]
  );

  const updateNewUserEmail = (e) => {
    setNewEmail(e.target.value);
    // setNewUser({ ...newUser, email: e.target.value })
  };

  const isEmailValid = (e) => {
    return e.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
  };

  const handleNewUserPermission = (option) => {
    setCurrentPermission(option);
  };

  const handlerAddUser = () => {
    if (!isEmailValid(newEmail)) {
      toast.error("E-mail inválido");
      return;
    }

    const newUser = { email: newEmail, role: currentPermission.value };

    userClient
      .create(newUser)
      .then((data) => {
        setUsers([
          { ...newUser, id: data.user_id, user_account: data },
          ...users,
        ]);
        setCurrentPermission(permissionsOptions[0]);
        setNewEmail("");
        toast.success("Usuário adicionado com sucesso!");
      })
      .catch((error) => {
        toast.error(error.response.data.message || "Erro ao adicionar usuário");
      });
  };

  const handleRemoveUser = (user_id) => {
    if (window.confirm("Realmente deseja remover esse usuário?")) {
      userClient
        .destroy(user_id)
        .then(() => {
          setUsers(users.filter((u) => u.id !== user_id));
          toast.success("Usuário removido com sucesso!");
        })
        .catch((error) => {
          toast.error(error.response.data.message || "Erro ao remover usuário");
        });
    }
  };

  const handleDisableUser = (user_id, option) => {
    userClient
      .update(user_id, { active: option.value })
      .then(() => {
        users.find((u) => u.id === user_id).user_account.disabled_at =
          option.value ? null : new Date();
        setUsers([...users]);
        toast.success("Usuário atualizado com sucesso!");
      })
      .catch((error) => {
        setUsers([...users]);
        toast.error(error.response.data.message || "Erro ao atualizar usuário");
      });
  };

  const handleChangeUserRole = (user_id, option) => {
    userClient
      .update(user_id, { role: option.value })
      .then(() => {
        users.find((u) => u.id === user_id).user_account.role = option.value;
        setUsers([...users]);
        toast.success("Usuário atualizado com sucesso!");
      })
      .catch((error) => {
        setUsers([...users]);
        toast.error(error.response.data.message || "Erro ao atualizar usuário");
      });
  };

  const getActiveOption = (disabled_at) => {
    if (disabled_at) return activeOptions[1];
    return activeOptions[0];
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);

    userClient.getAll(0, 50, { query: e.target.value }).then((response) => {
      setUsers(response.data);
    });
  };

  useEffect(() => {
    meClient.getCurrentUserAccount().then((data) => {
      setCurrentUserAccount(data);
    });

    userClient.getAll(0, 50).then((response) => {
      setUsers(response.data);
    });
  }, []);

  return (
    <>
      <Row>
        <Col sm={12}>
          <h1>
            <i className="fa-solid fa-users"></i> Usuários
          </h1>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <Card>
            <CardHeader>
              <Row>
                <Col sm={8}>
                  <input
                    id="filter"
                    className="form-control"
                    placeholder="Buscar"
                    style={{ width: "400px" }}
                    onChange={handleFilterChange}
                    value={filter}
                  />
                </Col>
                <Col sm={4} style={{ textAlign: "right" }}>
                  {/*<a*/}
                  {/*    className="btn btn-outline-primary"*/}
                  {/*    href={"/administration/users/new"}*/}
                  {/*>*/}
                  {/*    <span className="btn-inner--icon">*/}
                  {/*      <i className="fa-solid fa-plus" />*/}
                  {/*    </span>*/}
                  {/*    <span className="btn-inner--text">Novo</span>*/}
                  {/*</a>*/}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm={7}>
                  <table className={"table"}>
                    <thead>
                      <tr>
                        <th>Email</th>
                        <th>Permissão</th>
                        <th>Ativo?</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => (
                        <tr key={user.id}>
                          <td>
                            <span>{user.email}</span>
                            {currentUserAccount?.user_id === user.id && (
                              <span
                                className={"tag"}
                                style={{ marginLeft: "10px" }}
                              >
                                Você
                              </span>
                            )}
                          </td>
                          <td style={{ width: "200px" }}>
                            {currentUserAccount?.user_id !== user.id && (
                              <Select
                                value={permissionsOptions.find(
                                  (s) => s.value === user.user_account.role
                                )}
                                classNamePrefix="select"
                                options={permissionsOptions}
                                onChange={(option) => {
                                  handleChangeUserRole(user.id, option);
                                }}
                              />
                            )}
                            {currentUserAccount?.user_id === user.id && (
                              <span className={"tag"}>
                                {PERMISSION_LABEL[currentUserAccount.role]}
                              </span>
                            )}
                          </td>
                          <td style={{ width: "200px" }}>
                            {currentUserAccount?.user_id !== user.id && (
                              <Select
                                value={getActiveOption(
                                  user.user_account.disabled_at
                                )}
                                classNamePrefix="select"
                                options={activeOptions}
                                onChange={(option) =>
                                  handleDisableUser(user.id, option)
                                }
                              />
                            )}
                            {currentUserAccount?.user_id === user.id && (
                              <span className={"tag"}>
                                {
                                  getActiveOption(user.user_account.disabled_at)
                                    .label
                                }
                              </span>
                            )}
                          </td>
                          <td style={{ width: 0 }}>
                            {currentUserAccount?.user_id !== user.id && (
                              <Button
                                className={"btn-outline-danger"}
                                onClick={() => handleRemoveUser(user.id)}
                              >
                                <i className={"fa fa-times"}></i>
                                <span>Remover</span>
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Col>
                <Col sm={1}></Col>
                <Col sm={3}>
                  <Row>
                    <h4>Adicionar usuário</h4>
                  </Row>
                  <Row>
                    <Input
                      value={newEmail}
                      onChange={updateNewUserEmail}
                      placeholder="E-mail"
                      className="mb-2"
                    />
                    {newEmail && !isEmailValid(newEmail) && (
                      <span className={"text-danger"}>e-mail inválido</span>
                    )}
                  </Row>
                  <Row>
                    <div style={{ width: "100%" }}>
                      <Select
                        className="mb-2"
                        value={currentPermission}
                        options={permissionsOptions}
                        onChange={handleNewUserPermission}
                      />
                    </div>
                  </Row>
                  <Row>
                    <Button
                      className="btn btn-outline-primary btn-expand"
                      onClick={handlerAddUser}
                    >
                      <span className="btn-inner--icon">
                        <i className="fa-solid fa-plus" />
                      </span>
                      <span className="btn-inner--text">Adicionar</span>
                    </Button>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UserIndex;
