function isAuthenticated() {
  const hasToken = !!localStorage.getItem("token");

  if (!hasToken) return false;

  const loggedAt = parseInt(localStorage.getItem("logged_at")) + (7 * 24 * 60 * 60 * 1000);
  const now = new Date().getTime();

  return now <= loggedAt;
}

module.exports = {
  isAuthenticated,
};
