import {useState} from 'react';
import {DatePicker} from "antd";
import dayjs from 'dayjs';
import {Button, ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup} from "reactstrap";
import Select from "react-select";

import utc from 'dayjs/plugin/utc';
import {toast} from "react-toastify";
dayjs.extend(utc)


const relative_lavel = {
    min: 'Min',
    hour: 'Horas',
    day: 'Dias'
}

function getNewStartAt() {
    const defaultStatAt = new Date();
    defaultStatAt.setSeconds(0);
    defaultStatAt.setMilliseconds(0);
    defaultStatAt.setMinutes(defaultStatAt.getMinutes() - 15);
    return defaultStatAt
}

const hourOptions = [
    { value: "00:00", label: "00:00" },
    { value: "01:00", label: "01:00" },
    { value: "02:00", label: "02:00" },
    { value: "03:00", label: "03:00" },
    { value: "04:00", label: "04:00" },
    { value: "05:00", label: "05:00" },
    { value: "06:00", label: "06:00" },
    { value: "07:00", label: "07:00" },
    { value: "08:00", label: "08:00" },
    { value: "09:00", label: "09:00" },
    { value: "10:00", label: "10:00" },
    { value: "11:00", label: "11:00" },
    { value: "12:00", label: "12:00" },
    { value: "13:00", label: "13:00" },
    { value: "14:00", label: "14:00" },
    { value: "15:00", label: "15:00" },
    { value: "16:00", label: "16:00" },
    { value: "17:00", label: "17:00" },
    { value: "18:00", label: "18:00" },
    { value: "19:00", label: "19:00" },
    { value: "20:00", label: "20:00" },
    { value: "21:00", label: "21:00" },
    { value: "22:00", label: "22:00" },
    { value: "23:00", label: "23:00" },
]

const hourEndOptions = [
    { value: "00:59", label: "00:59" },
    { value: "01:59", label: "01:59" },
    { value: "02:59", label: "02:59" },
    { value: "03:59", label: "03:59" },
    { value: "04:59", label: "04:59" },
    { value: "05:59", label: "05:59" },
    { value: "06:59", label: "06:59" },
    { value: "07:59", label: "07:59" },
    { value: "08:59", label: "08:59" },
    { value: "09:59", label: "09:59" },
    { value: "10:59", label: "10:59" },
    { value: "11:59", label: "11:59" },
    { value: "12:59", label: "12:59" },
    { value: "13:59", label: "13:59" },
    { value: "14:59", label: "14:59" },
    { value: "15:59", label: "15:59" },
    { value: "16:59", label: "16:59" },
    { value: "17:59", label: "17:59" },
    { value: "18:59", label: "18:59" },
    { value: "19:59", label: "19:59" },
    { value: "20:59", label: "20:59" },
    { value: "21:59", label: "21:59" },
    { value: "22:59", label: "22:59" },
    { value: "23:59", label: "23:59" },
]

function DefaultDatePicker({ setConfiguration, _dateType = 'relative', _relativeUnit = 'min', _relativeValue = 30, _startAt, _endAt }) {
    const [dateType, setDateType] = useState(_dateType);
    const [relativeUnit, setRelativeUnit] = useState(_relativeUnit);
    const [relativeValue, setRelativeValue] = useState(_relativeValue);
    const [startAt, setStartAt] = useState(_startAt);
    const [endAt, setEndAt] = useState(_endAt);

    const [selectedStartHour, setSelectedStartHour] = useState(hourOptions.find((hour) => hour.value === startAt.format('HH:00')));
    const [selectedEndHour, setSelectedEndHour] = useState(hourEndOptions.find((hour) => hour.value === endAt.format('HH:59')));

    const setConfigurationHandler = (params) => {
        setConfiguration({dateType, relativeUnit, relativeValue, startAt, endAt, ...params});
    }

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const getRelativeName = () => {
        return relative_lavel[relativeUnit]
    }

    const setRelativeUnitHandler = (unit) => () => {
        setRelativeUnit(unit);
        setConfigurationHandler({ relativeUnit: unit })
    };

    const setRelativeValueHandler = (e) => {
        setRelativeValue(e.target.value);
        setConfigurationHandler({ relativeValue: e.target.value })
    }

    const handleButtonClick = (type) => {
        setDateType(type);
        setConfigurationHandler({ dateType: type })
    };

    const selectStartHourHandler = (option) => {
        if(dayjs(startAt.format('YYYY-MM-DD') + ' ' + option.value + ":00") >= dayjs(endAt.format('YYYY-MM-DD') + ' ' + selectedEndHour.value + ":00")) {
            toast.error("Data/Hora início precisa ser menor a hora final")
            return;
        }

        setSelectedStartHour(option);
        onChange(startAt, option.value, endAt, selectedEndHour.value)
    }

    const selectEndHourHandler = (option) => {
        if(dayjs(startAt.format('YYYY-MM-DD') + ' ' + selectedStartHour.value + ":00") >= dayjs(endAt.format('YYYY-MM-DD') + ' ' + option.value + ":00")) {
            toast.error("Data/Hora início precisa ser menor a hora final")
            return;
        }

        setSelectedEndHour(option);
        onChange(startAt, selectedStartHour.value, endAt, option.value)
    }

    const onChangeStart = (_startAt) => {
        if(dayjs(_startAt.format('YYYY-MM-DD') + ' ' + selectedStartHour.value + ":00") >= dayjs(endAt.format('YYYY-MM-DD') + ' ' + selectedEndHour.value + ":00")) {
            toast.error("Data/Hora início precisa ser menor a hora final")
            return;
        }

        setStartAt(_startAt)
        onChange(_startAt, selectedStartHour.value, endAt, selectedEndHour.value)
    }

    const onChangeEnd = (_endAt) => {
        if(dayjs(startAt.format('YYYY-MM-DD') + ' ' + selectedStartHour.value + ":00") >= dayjs(_endAt.format('YYYY-MM-DD') + ' ' + selectedEndHour.value + ":00")) {
            toast.error("Data/Hora início precisa ser menor a hora final")
            return;
        }

        setEndAt(_endAt)
        onChange(startAt, selectedStartHour.value, _endAt, selectedEndHour.value)
    }

    const onChange = (_startAt, _startHour, _endAt, _endHour) => {
        const st = dayjs(_startAt.format('YYYY-MM-DD') + ' ' + _startHour + ":00");
        const et = dayjs(_endAt.format('YYYY-MM-DD') + ' ' + _endHour + ":00");
        setConfigurationHandler({ startAt: st, endAt: et })
    };

    return (
        <div className={"d-flex justify-content-between align-items-end clock-area default-date-picker"}>
            <ButtonGroup>
                <Button className={dateType === 'fixed' ? 'btn btn-default' : 'btn btn-secondary'} style={{height: '48px'}}
                        onClick={() => handleButtonClick('fixed')}>
                    Fixo
                </Button>
                <Button className={dateType === 'relative' ? 'btn btn-default' : 'btn btn-secondary'}
                        onClick={() => handleButtonClick('relative')}>
                    Relativo
                </Button>
            </ButtonGroup>

            {dateType === 'fixed' && (
                <>
                    <div className={"d-flex justify-content-between align-items-end align-middle"}>
                        <small style={{textAlign: "right", height: '48px', lineHeight: '48px', margin: '0 5px'}}>Início</small>
                        <div style={{width: '120px', margin: '0 5px'}}>
                            <DatePicker
                                value={startAt}
                                format="DD-MM-YYYY"
                                onChange={onChangeStart}
                                style={{
                                    padding: '5px 10px',
                                    height: '48px',
                                    marginLeft: '0',
                                    boxShadow: 'none',
                                    width: '100%',
                                    border: '1px solid #ccc'
                                }}
                            />
                        </div>
                        <div style={{width: "120px", margin: '0 5px'}}>
                            <Select value={selectedStartHour}
                                    classNamePrefix="select"
                                    onChange={selectStartHourHandler}
                                    options={hourOptions}
                            />
                        </div>
                    </div>


                    <div className={"d-flex justify-content-between align-items-end align-middle"}>
                        <small style={{textAlign: "right", height: '48px', lineHeight: '48px'}}>Até</small>
                        <div style={{width: '120px', margin: '0 5px'}}>
                            <DatePicker
                                value={endAt}
                                format="DD-MM-YYYY"
                                onChange={onChangeEnd}
                                style={{
                                    padding: '5px 10px',
                                    height: '48px',
                                    marginLeft: '0',
                                    boxShadow: 'none',
                                    border: '1px solid #ccc'
                                }}
                            />
                        </div>
                        <div style={{width: "120px", margin: '0 0 0 5px'}}>
                            <Select value={selectedEndHour}
                                    classNamePrefix="select"
                                    onChange={selectEndHourHandler}
                                    options={hourEndOptions}
                            />
                        </div>
                    </div>
                </>
            )}

            {dateType === 'relative' && (
                <>
                    <InputGroup style={{marginLeft: '10px'}} className={"relative-input-group"}>
                        <Input className={"text-default"} type={"number"}
                               style={{border: "0", "textAlign": "center", width: '70px', height: '48px'}} value={relativeValue}
                               onChange={setRelativeValueHandler}/>
                        <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} >
                            <DropdownToggle caret style={{borderRadius: "0 5px 5px 0", boxShadow: "none", color: '#fafafa'}} className={"bg-green"}>
                                {getRelativeName()}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem key={"min"} onClick={setRelativeUnitHandler("min")}>Min</DropdownItem>
                                <DropdownItem key={"hour"}
                                              onClick={setRelativeUnitHandler("hour")}>Hora</DropdownItem>
                                <DropdownItem key={"day"} onClick={setRelativeUnitHandler("day")}>Dia</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </InputGroup>
                </>
            )}
        </div>
    );
}

export default DefaultDatePicker;