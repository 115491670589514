import ApiClient from "./api";

class ApikeyClient extends ApiClient {
  constructor() {
    super();
  }

  getAll(page = 0, limit = 10, query = {}) {
    try {
      page = page === 0 ? 0 : page - 1;
      let queryString = new URLSearchParams(query).toString();
      return this.get(
        `api/v2/administration/api_keys?page=${page}&per_page=${limit}&count=true&${queryString}`
      );
    } catch (error) {
      return [];
    }
  }

  create(data) {
    try {
      return this.post("api/v2/administration/api_keys", data);
    } catch (error) {
      throw error;
    }
  }

  edit(id, data) {
    try {
      return this.patch(`api/v2/administration/api_keys/${id}`, data);
    } catch (error) {
      throw error;
    }
  }

  exclude(id) {
    try {
      return this.delete(`api/v2/administration/api_keys/${id}`);
    } catch (error) {
      throw error;
    }
  }
}

export default ApikeyClient;
