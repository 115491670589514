import React, { useState, useEffect } from "react";
import {Link, useLocation} from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Navbar,
  Container,
  Row, Col, Dropdown, Nav, NavItem, Button,
} from "reactstrap";
import MeClient from "../../apis/me";
import logo from '../../assets/img/brand/s42-logo.svg';


const AdminNavbar = (props) => {
  const { search } = useLocation();
  const [accounts, setAccounts] = useState([]);
  const [currentAccount, setCurrentAccount] = useState(null);
  const meClient = new MeClient();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await meClient.getAccounts();
        setAccounts(data);
      } catch (error) {
        console.error("Error fetching accounts:", error);
      }
    };

    try {
      const account = JSON.parse(localStorage.getItem("current_account"));
      setCurrentAccount(account);
    } catch (err) {
      return "Sem Conta";
    }

    fetchData();
  }, []);

  const switchAccount = async (accountId) => {
    if (await meClient.switchAccount(accountId)) {
      localStorage.setItem("current_account_id", accountId);
      setCurrentAccount(accounts.find((account) => account.id === accountId));
      if(window.location.pathname === "/switch_account") {
        window.location.href = "/";
      } else {
        window.location.reload();
      }
      return;
    }
  };


  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };


  return (
      <>
        <Navbar className="navbar-top navbar-dark" id="navbar-main"
                style={{'position': 'fixed', background: '#fafafa', borderBottom: '1px solid #ccc'}}>
          <Container fluid>
            <Row style={{width: '100%', margin: 0}}>
              <Col sm={6} className={"d-flex align-items-start"} style={{padding: 0}}>
                <Link className="navbar-brand" to={"/" + search} inline>
                  <img
                      alt="..."
                      src={logo}
                      style={{ height: "40px" }}
                  />
                </Link>
              </Col>

              <Col sm={6} className={"d-flex justify-content-end align-items-end"} style={{padding: 0}}>
                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} style={{marginTop: '2px', margin: 0}} id={"dropdown-accounts"}>
                  <DropdownToggle caret className={"bg-green"} style={{'color': '#fafafa'}}>
                    {currentAccount ? currentAccount.name : "Selecione uma Conta"}
                  </DropdownToggle>
                  <DropdownMenu>
                    {accounts.map((account) => (
                        <DropdownItem className="noti-title" header tag="div" key={account.id}>
                          <Button className={"btn-link btn-expand"} onClick={() => switchAccount(account.id)}>
                            {account.name}
                          </Button>
                        </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
                {/*<GlobalDatePicker />*/}
              </Col>
            </Row>
          </Container>
        </Navbar>
      </>
  );
};

export default AdminNavbar;
