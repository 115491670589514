import React from "react";
import {useLocation, Route, Routes, Navigate} from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import ServiceView from "views/apm/services";
import ApiKeysView from "../views/administration/api-key/Index";
import SwitchAccountView from "views/auth/SwitchAccountView";
import ApiKeysNewView from "../views/administration/api-key/new";
import ChannelIndexView from "../views/monitoring/channels";
import ChannelFormView from "../views/monitoring/channels/form";
import AlarmIndexView from "../views/monitoring/alarms";
import AlarmFormView from "../views/monitoring/alarms/form";
import IncidentIndexView from "../views/monitoring/incidents";
import UserIndex from "../views/administration/users/UserIndex";
import ConsumptionIndex from "../views/administration/consumptions/ConsumptionIndex";
import TraceIndex from "../views/apm/traces/TraceIndex";
import BillingIndex from "../views/administration/billings/BillingIndex";
import PaymentIndex from "../views/administration/payment/PaymentIndex";
import CreditIndex from "../views/administration/credits/CreditIndex";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props?.location?.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          brandText={getBrandText(props?.location?.pathname)}
        />

        <Sidebar  />

        <div style={{marginLeft: '200px', marginTop: '80px'}}>
          <Container fluid id={"main-container"}>
            <Routes>
              {/*<Route path='/' element={<ServiceView/>} exact/>*/}
              <Route path='/switch_account' element={<SwitchAccountView/>} exact/>
              <Route path='/apm/services' element={<ServiceView/>} exact/>
              {/*<Route path='/apis/:service_name' element={<ServiceView/>} exact/>*/}
              <Route path='/apm/traces' element={<TraceIndex/>} exact/>
              {/*<Route path='/service/:service_id/transactions/:transaction' element={<ServiceTransactionShowView/>} exact/>*/}
              <Route path='/monitoring/channels' element={<ChannelIndexView />} exact/>
              <Route path='/monitoring/channels/new' element={<ChannelFormView />} exact/>
              <Route path='/monitoring/channels/:id/edit' element={<ChannelFormView />} exact/>
              <Route path='/monitoring/alarms' element={<AlarmIndexView />} exact/>
              <Route path='/monitoring/alarms/new' element={<AlarmFormView />} exact/>
              <Route path='/monitoring/alarms/:id/edit' element={<AlarmFormView />} exact/>
              <Route path='/monitoring/incidents' element={<IncidentIndexView />} exact/>
              <Route path='/administration/users' element={<UserIndex />} exact/>
              <Route path='/administration/api_keys/new' element={<ApiKeysNewView/>} exact/>
              <Route path='/administration/api_keys' element={<ApiKeysView/>} exact/>
              <Route path='/administration/consumptions' element={<ConsumptionIndex />} exact/>
              <Route path='/administration/billings' element={<BillingIndex/>} exact/>
              <Route path='/administration/payments' element={<PaymentIndex/>} exact/>
              <Route path='/administration/credits' element={<CreditIndex/>} exact/>
              <Route
                  path="*"
                  element={<Navigate to="/apm/services" replace />}
              />
            </Routes>
          </Container>
        </div>

        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
