/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Modal,
  CardFooter,
  ButtonGroup,
} from "reactstrap";
import { useState, useEffect } from "react";

import { toast } from "react-toastify";

import AlarmClient from "../../../apis/alarms";
import { Link, useSearchParams } from "react-router-dom";

const OPERATOR_ENUM = {
  // equal: 0,
  // not_equal: 1,
  greater_than: ">",
  greater_than_or_equal: ">=",
  less_than: "<",
  less_than_or_equal: "<=",
};

const AlarmIndexView = () => {
  const defaultAlarm = {
    id: null,
    name: "",
    api_key: "",
    active: true,
  };

  const [alarm, setAlarm] = useState(defaultAlarm);
  const [alarms, setAlarms] = useState([]);
  const [formModal, setFormModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [secretModal, setSecretModal] = useState(false);
  const alarmClient = new AlarmClient();

  const toggleModal = (name) => {
    if (name === "form") {
      setFormModal(!formModal);
    } else if (name === "confirm") {
      setConfirmModal(!confirmModal);
    } else if (name === "secret") {
      setSecretModal(!secretModal);
    }
  };

  const posSubmit = () => {
    setConfirmModal(false);
    fetchData({ page: 0, sizePerPage: 10 });
    toast.success("Operação realizada com sucesso!");
  };

  const onDelete = async (data) => {
    setAlarm(data);
    setConfirmModal(!confirmModal);
  };

  const confirmDelete = async () => {
    try {
      await alarmClient.exclude(alarm.id);
      posSubmit();
    } catch (error) {
      toast.error("Não foi possível deletar o registro.");
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const [queryName, setQueryName] = useState("");

  const fetchData = async ({ page, sizePerPage, filter }) => {
    const query = filter ? { query: filter || undefined } : {};
    const response = await alarmClient.getAll(page, sizePerPage, query);
    setAlarms(response.data);
  };

  const onFilter = async (e) => {
    setSearchParams({ name: e.target.value });
    setQueryName(e.target.value);
    await fetchData({ page: 0, sizePerPage: 10, filter: e.target.value });
  };

  const buildMetric = (alarm) => {
    let label = "";
    let sufix = "";

    switch (alarm.metric) {
      case "error_rate":
        label = "Taxa de Erros";
        sufix = "%";
        break;
      case "response_time":
        label = "Tempo de Resposta";
        sufix = "ms";
        break;
      case "error_throughput":
        label = "Quantidade de Erros";
        break;
      case "throughput":
        label = "Quantidade de Execuções";
        break;
      default:
        label = "Métrica Desconhecida";
        break;
    }

    label += ` ${OPERATOR_ENUM[alarm.operator]} ${
      alarm.threshold
    }${sufix} por ${alarm.trigger_times} em um período de ${
      alarm.wait_times * alarm.interval_min
    } minuto(s)`;

    return label;
  };

  useEffect(() => {
    setQueryName(searchParams.get("name"));
    fetchData({ page: 0, sizePerPage: 10, filter: searchParams.get("name") });
  }, []);

  return (
    <>
      <Row>
        <Col sm={12}>
          <h1>
            <i className={"fa-solid fa-bell"}></i> Alarmes
          </h1>
        </Col>
      </Row>

      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="">
              <Row>
                <Col md="10">
                  <input
                    id="filter"
                    className="form-control"
                    placeholder="Buscar"
                    style={{ width: "400px" }}
                    onChange={onFilter}
                    value={queryName}
                  />
                </Col>
                <Col md="2" className="text-right">
                  <Link
                    className="btn btn-outline-primary"
                    type="button"
                    to={"/monitoring/alarms/new"}
                  >
                    <span className="btn-inner--icon">
                      <i className="fa-solid fa-plus" />
                    </span>
                    <span className="btn-inner--text">Novo</span>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className={"horizontal-scroll"}>
              <table className={"table default-table"}>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Condição</th>
                    <th>Serviços</th>
                    <th>Canais</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {alarms.map((alarm, index) => (
                    <tr key={index}>
                      <td>{alarm.name}</td>
                      <td style={{ width: 0 }}>{buildMetric(alarm)}</td>
                      <td style={{ width: 0 }}>
                        {alarm.services_names.join(", ")}
                      </td>
                      <td style={{ width: 0 }}>
                        {alarm.channels.map((channel) => (
                          <span className={"tag gray"}>{channel.name}</span>
                        ))}
                      </td>
                      <td style={{ width: 0 }}>
                        <ButtonGroup>
                          <Link
                            to={`/monitoring/alarms/${alarm.id}/edit`}
                            className={"btn btn-secondary btn-small"}
                          >
                            Editar
                          </Link>
                          <Button
                            onClick={() => onDelete(alarm)}
                            className={"btn-danger btn-small"}
                          >
                            Deletar
                          </Button>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </div>
      </Row>

      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={confirmModal}
        toggle={() => toggleModal("confirm")}
      >
        <div className="modal-body p-0">
          <Card className="bg-secondary shadow border-0">
            <CardHeader>
              <h3 className="mb-0">Confirmação</h3>
            </CardHeader>
            <CardBody>Deseja mesmo deletar?</CardBody>
            <CardFooter className="text-right">
              <Button
                color="secondary"
                type="button"
                onClick={() => {
                  toggleModal("confirm");
                }}
              >
                Cancelar
              </Button>
              <Button color="danger" type="button" onClick={confirmDelete}>
                Deletar
              </Button>
            </CardFooter>
          </Card>
        </div>
      </Modal>
    </>
  );
};

export default AlarmIndexView;
