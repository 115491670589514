
function getTimeUnit(nanoseconds) {
    if(nanoseconds < 1000) {
        return `ns`
    }

    if(nanoseconds < 1000000) {
        return `µs`
    }

    if(nanoseconds < 1000000000) {
        return `ms`
    }

    if(nanoseconds < 60000000000) {
        return `s`
    }

    if(nanoseconds < 3600000000000) {
        return `m`
    }

    if(nanoseconds < 86400000000000) {
        return `h`
    }

    return `d`
}

function formatTimeToUnit(nanoseconds, unit) {
    if(unit === 'ns') {
        return nanoseconds;
    }

    if(unit === 'µs') {
        return nanoseconds.map(ns => ns / 1000);
    }

    if(unit === 'ms') {
        return nanoseconds.map(ns => ns / 1000000);
    }

    if(unit === 's') {
        return nanoseconds.map(ns => ns / 1000000000);
    }

    if(unit === 'm') {
        return nanoseconds.map(ns => ns / 60000000000);
    }

    if(unit === 'h') {
        return nanoseconds.map(ns => ns / 3600000000000);
    }

    return nanoseconds.map(ns => ns / 86400000000000);
}

// should return NUMBER+UNIT
// UNITS=nano, micro, milli, second, minute, hour, day
function formatTime(nanoseconds) {
    if(nanoseconds < 1000) {
        return `${nanoseconds}ns`
    }

    if(nanoseconds < 1000000) {
        return `${(nanoseconds / 1000).toFixed(2)}µs`
    }

    if(nanoseconds < 1000000000) {
        return `${(nanoseconds / 1000000).toFixed(2)}ms`
    }

    if(nanoseconds < 60000000000) {
        return `${(nanoseconds / 1000000000).toFixed(2)}s`
    }

    if(nanoseconds < 3600000000000) {
        return `${(nanoseconds / 60000000000).toFixed(2)}m`
    }

    if(nanoseconds < 86400000000000) {
        return `${(nanoseconds / 3600000000000).toFixed(2)}h`
    }

    return `${(nanoseconds / 86400000000000).toFixed(2)}d`
}

module.exports = {
    getTimeUnit,
    formatTimeToUnit,
    formatTime
}