import {
    Alert,
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Col,
} from "reactstrap";
import { useState } from "react";

import AuthClient from "../../apis/auth";
import {Link} from "react-router-dom";
import {Divider} from "antd";

const auth = new AuthClient();

const CreateUser = () => {
    const [username, setUsername] = useState("");
    const [userCreated, setUserCreated] = useState(false);

    const createUser = async (e) => {
        auth.createUser(username).then(() => {
            setUserCreated(true)
        });
        e.preventDefault()
    };


    return (
        <>
            <Col lg="4" md="7">
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-4 py-lg-4">
                        {userCreated && (<span>Usuário cadastrado com sucesso! Verifique o seu e-mail</span>)}
                        {!userCreated && (<Form role="form" onSubmit={createUser}>
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-single-02"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Usuário"
                                        type="email"
                                        required={true}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <Button
                                className=""
                                color="primary"
                                type="submit"
                                style={{width: "100%"}}
                            >
                                Cadastrar
                            </Button>
                        </Form>)}

                        <Divider />

                        <div className="text-center">
                            <Link to={"/auth/login"}>
                                Entrar
                            </Link>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default CreateUser;
