import {Card, CardBody, Col, Row} from "reactstrap";

const PaymentIndex = () => {
    return (
        <>
            <Row>
                <Col sm={12}>
                    <h1><i className="fa-solid fa-money-bill"></i> Pagamentos</h1>
                </Col>
            </Row>

            <Row>
                <Col sm={12}>
                    <Card>
                        <CardBody>
                            <p>Em desenvolvimento</p>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default PaymentIndex