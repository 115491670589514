import ApiClient from "./api";

class TransactionsClient extends ApiClient {
  constructor() {
    super();
  }

  getMetrics(queryParams = {}) {
    let queryString = new URLSearchParams(queryParams).toString();
    return this.get(`/api/v2/transactions/metrics?${queryString}`)
  }
}

export default TransactionsClient;
