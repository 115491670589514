/* eslint-disable react-hooks/exhaustive-deps */
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col
} from "reactstrap";
import { useState, useEffect } from "react";
import ServiceClient from "../../../apis/service";
import {useSearchParams} from "react-router-dom";
import dayjs from "dayjs";
import DefaultDatePicker from "../../../components/date/DataPicker";
import {Line} from "react-chartjs-2";
import HorizontalSpace from "../../../components/HorizontalSpace";
import Select from "react-select";
import {toast} from "react-toastify";
import {InfinitySpin} from "react-loader-spinner";
import TransactionAttributesClient from "../../../apis/transaction_attributes";

import {
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Chart,
    Filler
} from 'chart.js';

import {
    getTimeUnit,
    formatTimeToUnit,
    formatTime
} from "../../../helpers/time";

function getNewStartAt() {
    const defaultStatAt = new Date();
    defaultStatAt.setSeconds(0);
    defaultStatAt.setMilliseconds(0);
    defaultStatAt.setMinutes(defaultStatAt.getMinutes() - 30);
    defaultStatAt.setMinutes(0)
    return defaultStatAt
}

function pad(num, size, char = '0') {
    num = num.toString();
    while (num.length < size) num = char + num;
    return num;
}

function getEndDate() {
    const d = new Date()
    d.setSeconds(0);
    d.setMilliseconds(0);
    d.setMinutes(59);
    return d
}

function min(values) {
    return values.reduce((a, b) => Math.min(a, b));
}

function max(values) {
    return values.reduce((a, b) => Math.max(a, b));
}

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

Array.prototype.max = function() {
    return Math.max.apply(null, this);
};

Array.prototype.min = function() {
    return Math.min.apply(null, this);
};

const serviceClient = new ServiceClient();
const transactionAttributeClient = new TransactionAttributesClient();

// should return gradient COLOR between green (0.0) and red (1.0) (with 5 steps)
function getColorError(error_rate) {
    const colors = [
        '#4CBC8C',
        '#FFD83D',
        '#FF8C3D',
        '#FF6600',
        '#FF3300',
    ];

    if(error_rate < 0.2) return colors[0];
    if(error_rate < 0.4) return colors[1];
    if(error_rate < 0.6) return colors[2];
    if(error_rate < 0.8) return colors[3];
    return colors[4];
}

const ServiceView = () => {
    let [searchParams, setSearchParams] = useSearchParams();

    const [service_name, setServiceName] = useState(searchParams.get("serviceName") || "");
    const [serviceOptions, setServiceOptions] = useState([])
    const [selectedService, setSelectedService] = useState([])
    const [isLoading, setIsLoading] = useState(false);

    const [rows, setRows] = useState([]);
    const [transactionName, setTransactionName] = useState(decodeURIComponent(searchParams.get("transactionName") || "*"));

    const defaultStartAt = getNewStartAt()

    const [dateType, setDateType] = useState(searchParams.get("dateType") || 'relative');
    const [relativeUnit, setRelativeUnit] = useState(searchParams.get("relativeUnit") || 'min');
    const [relativeValue, setRelativeValue] = useState(parseInt(searchParams.get("relativeValue") || '30'));
    const [startAt, setStartAt] = useState( searchParams.get("startAt") ? dayjs.unix(parseInt(searchParams.get("startAt"))) : dayjs(defaultStartAt));
    const [endAt, setEndAt] = useState( searchParams.get("endAt") ? dayjs.unix(parseInt(searchParams.get("endAt"))) : dayjs(getEndDate()));

    const [reportExecutionMetrics, setReportExecutionMetrics] = useState({
        fill: true,
        labels: [],
        datasets: []
    });
    const [options, setOptions] = useState({
        responsive: true,
        scales: {},
        plugins: {},
        interaction: {}
    })

    const [reportExecutionMetricsApdex, setReportExecutionMetricsApdex] = useState({
        fill: true,
        labels: [],
        datasets: []
    });

    const [httpStatusMetrics, setHttpStatusMetrics] = useState({
        fill: true,
        labels: [],
        datasets: []
    });


    const [optionsApdex, setOptionsApdex] = useState({
        responsive: true,
        scales: {},
        plugins: {},
        interaction: {}
    })

    const [optionsHttpStatus, setOptionsHttpStatus] = useState({
        responsive: true,
        scales: {},
        plugins: {},
        interaction: {}
    })

    const [reportExecutionMetricsLatency, setReportExecutionMetricsLatency] = useState({
        fill: true,
        labels: [],
        datasets: []
    });
    const [optionsLatency, setOptionsLatency] = useState({
        responsive: true,
        scales: {},
        plugins: {},
        interaction: {}
    })
    const [reportExecutionMetricsErrors, setReportExecutionMetricsErrors] = useState({
        fill: true,
        labels: [],
        datasets: []
    });
    const [optionsErrors, setOptionsErrors] = useState({
        responsive: true,
        scales: {},
        plugins: {},
        interaction: {}
    })

    const fetchData = async (params) => {
        if(!(params.service_name || service_name)) return;
        const newParams = {
            ...params,
            startAt: params.startAt.unix(),
            endAt: params.endAt.unix(),
        }

        return serviceClient.getReportTransactionResume(params.service_name || service_name, newParams).then((_rows) => {
            _rows.forEach(r => {
                r['total_executions'] = parseInt(r['total_executions'])
                r['total_success'] = parseInt(r['total_success'])
                r['total_error'] = parseInt(r['total_error'])
                r['error_rate'] = parseFloat(r['error_rate'])
                r['apdex_score'] = parseFloat(r['apdex_score'])
                r['avg_duration'] = parseFloat(r['avg_duration'])
            });


            setRows(_rows)
        })
    }

    const fetchDataTransaction = async (params) => {
        if(!(params.service_name || service_name)) return;

        let startAt = params.startAt;
        let endAt = params.endAt;

        const map_unit = {
            'min': 'minute',
        }

        if(params.dateType === 'relative') {
            startAt = dayjs().utc().subtract(params.relativeValue, map_unit[params.relativeUnit] || params.relativeUnit);
            endAt = dayjs().utc();
        }

        const promiseAttributes = transactionAttributeClient.getMetrics({
            ...params,
            startAt: dayjs(startAt).format('YYYY-MM-DD HH:mm:ss'),
            endAt: dayjs(endAt).format('YYYY-MM-DD HH:mm:ss'),
            attribute_key: 'http.status_code',
            metric: 'counter'
        }).then((data) => {
            data['total_occurrences'] = data['total_occurrences'].map(value => parseInt(value));
            data['attribute_value'] = data['attribute_value'].map(value => parseInt(value));

            setOptionsHttpStatus({
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false
                },
                scales: {
                    'yLeft': {
                        type: 'linear',
                        position: 'left',
                        // min: 0,
                        // max: parseInt((max(data.total_occurrences) * 1.1).toFixed(0)),
                        grid: {
                            display:false
                        }
                    },
                    x: {
                        display: true,
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                             callback: function (value) {
                                return this.getLabelForValue(value.toString()).split(/T|\s/)[1].slice(0, 5)
                            }
                        },
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    legend: {
                        // display: false
                        position: 'bottom'
                    }
                },
            });

            const groupByStatusXX = {
                '1xx': {},
                '2xx': {},
                '3xx': {},
                '4xx': {},
                '5xx': {},
            };

            const httpStatusColors = {
                '1xx': '#666666', // gray
                '2xx': '#4cbc8c', // green
                '3xx': '#f1c40f', // yellow
                '4xx': '#e67e22', // orange
                '5xx': '#e74c3c', // red
            }

            data['attribute_value'].forEach((value, index) => {
                for (const i of [1, 2, 3, 4, 5]) {
                    if (value >= i*100 && value < (i*100+100)) {
                        const key = `${i}xx`;
                        if(!groupByStatusXX[key][data['ts_tz'][index]]) groupByStatusXX[key][data['ts_tz'][index]] = 0;
                        groupByStatusXX[key][data['ts_tz'][index]] += data['total_occurrences'][index];
                        break
                    }
                }
            })

            const dates = new Array(...new Set(data['ts_tz'] || data['ts']));

            const datasets = [];

            for(const key of Object.keys(groupByStatusXX)) {
                if(Object.keys(groupByStatusXX[key]).length === 0) continue;

                datasets.push({
                    label: key,
                    data: dates.map(dt => groupByStatusXX[key][dt] || 0),
                    borderColor: httpStatusColors[key],
                    yAxisID: 'yLeft',
                    pointRadius: 0,
                    fill: true,
                    backgroundColor: 'rgba(0, 0, 0, 0.0)',
                    borderWidth: 1,
                    animation: false
                })
            }

            setHttpStatusMetrics({
                labels:  dates,
                datasets
            });
        })

        const promiseTransactions = serviceClient.getReportExecutionMetrics(params.service_name || service_name, {
            ...params,
            startAt: params.startAt.unix(),
            endAt: params.endAt.unix(),
        }).then((data) => {
            data['total_count'] = data['total_count'].map(value => parseInt(value))
            data['total_success'] = data['total_success'].map(value => parseInt(value))
            data['total_error'] = data['total_error'].map(value => parseInt(value))
            data['total_duration'] = data['total_duration'].map(value => parseFloat(value))
            data['apdex_score'] = data['apdex_score'].map(value => parseFloat(value))
            data['p50'] = data['p50'].map(value => parseInt(value))
            data['p75'] = data['p75'].map(value => parseInt(value))
            data['p95'] = data['p95'].map(value => parseInt(value))
            data['p999'] = data['p999'].map(value => parseInt(value))

            setOptions({
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false
                },
                scales: {
                    'yLeft': {
                        type: 'linear',
                        position: 'left',
                        min: parseInt((min(data.total_count) * 0.9).toFixed(0)),
                        max: parseInt((max(data.total_count) * 1.1).toFixed(0)),
                        grid: {
                            display:false
                        }
                    },
                    x: {
                        display: true,
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                             callback: function (value) {
                                return this.getLabelForValue(value.toString()).split(/T|\s/)[1].slice(0, 5)
                            }
                        },
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    legend: {
                        // display: false
                        position: 'bottom'
                    }
                },
            })

            setReportExecutionMetrics({
                labels: data['ts_tz'] || data['ts'],
                datasets: [
                    {
                        label: 'Execuções',
                        data: data['total_count'],
                        borderColor: 'rgb(45,206,137)',
                        yAxisID: 'yLeft',
                        pointRadius: 0,
                        fill: true,
                        backgroundColor: 'rgba(45,206,137, 0.1)',
                        borderWidth: 1,
                        animation: false
                    }
                ]
            });

            const maxLatency = max(data['p999']);
            const unitFromLatency = getTimeUnit(maxLatency * 1.1);

            setOptionsLatency({
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false
                },
                scales: {
                    'yLeft': {
                        type: 'linear',
                        position: 'left',
                        min: 0,
                        max: parseInt(formatTimeToUnit([maxLatency * 1.1], unitFromLatency)[0].toFixed(0)),
                        grid: {
                            display:false
                        },
                    },
                    x: {
                        display: true,
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            callback: function (value) {
                                return this.getLabelForValue(value.toString()).split(/T|\s/)[1].slice(0, 5)
                            }
                        },
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function(tooltipItem) {
                                return tooltipItem.dataset.label + " - " + tooltipItem.raw.toFixed(0) + "" + unitFromLatency
                            }
                        }
                    },
                    legend: {
                        // display: false
                        position: 'bottom'
                    }
                },
            })

            setReportExecutionMetricsLatency({
                labels: data['ts_tz'] || data['ts'],
                datasets: [
                    // {
                    //     label: 'p50',
                    //     data: formatTimeToUnit(data['p50'], unitFromLatency),
                    //     borderColor: 'Violet',
                    //     yAxisID: 'yLeft',
                    //     pointRadius: 0,
                    //     // fill: true,
                    //     backgroundColor: 'rgba(238,130,238, 0.1)',
                    //     borderWidth: 1,
                    //     animation: false,
                    // },
                    {
                        label: 'p95',
                        data: formatTimeToUnit(data['p95'], unitFromLatency),
                        borderColor: 'orange',
                        yAxisID: 'yLeft',
                        pointRadius: 0,
                        // fill: true,
                        backgroundColor: 'rgb(30,144,255,0.1)',
                        borderWidth: 1,
                        animation: false
                    },
                    {
                        label: 'p99',
                        data: formatTimeToUnit(data['p99'], unitFromLatency),
                        borderColor: 'DodgerBlue',
                        yAxisID: 'yLeft',
                        pointRadius: 0,
                        // fill: true,
                        backgroundColor: 'rgba(255,165,0, 0.1)',
                        borderWidth: 1,
                        animation: false
                    },
                    {
                        label: 'p999',
                        data: formatTimeToUnit(data['p999'], unitFromLatency),
                        borderColor: '#4cbc8c',
                        yAxisID: 'yLeft',
                        pointRadius: 0,
                        // fill: true,
                        backgroundColor: 'rgba(255,165,0, 0.1)',
                        borderWidth: 1,
                        animation: false
                    }
                ]
            });

            setOptionsApdex({
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false
                },
                scales: {
                    'yLeft': {
                        type: 'linear',
                        position: 'left',
                        // min: 0,
                        max: 1,
                        grid: {
                            display: false
                        }
                    },
                    x: {
                        display: true,
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            callback: function (value) {
                                return this.getLabelForValue(value.toString()).split(/T|\s/)[1].slice(0, 5)
                            }
                        },
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    legend: {
                        position: 'bottom'
                    }
                },
            })

            setReportExecutionMetricsApdex({
                labels: data['ts_tz'] || data['ts'],
                datasets: [
                    {
                        label: 'Apdex Score',
                        data: data['apdex_score'].map(apdex => parseFloat(apdex)),
                        borderColor: 'rgb(45,206,137)',
                        yAxisID: 'yLeft',
                        pointRadius: 0,
                        backgroundColor: 'rgba(45,206,137, 0.1)',
                        borderWidth: 1,
                        animation: false,
                    }
                ]
            });

            setOptionsErrors({
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false
                },
                scales: {
                    'yLeft': {
                        type: 'linear',
                        position: 'left',
                        min: 0,
                        max: 100,
                        grid: {
                            display:false
                        },
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, values) {
                                return value.toFixed(0) + '%';
                            }
                        }
                    },
                    x: {
                        display: true,
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 6,
                            callback: function (value) {
                                return this.getLabelForValue(value.toString()).split(/T|\s/)[1].slice(0, 5)
                            }
                        },
                        grid: {
                            display: false
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                return (tooltipItem.raw).toFixed(2) + '%'
                            }
                        }
                    },
                    legend: {
                        display: false
                        // position: 'bottom'
                    }
                },
            })

            setReportExecutionMetricsErrors({
                labels: data['ts_tz'] || data['ts'],
                datasets: [
                    {
                        data: data['error_rate'].map(e => e * 100),
                        borderColor: 'red',
                        yAxisID: 'yLeft',
                        pointRadius: 0,
                        // fill: true,
                        backgroundColor: 'rgba(238,130,238, 0.1)',
                        borderWidth: 1,
                        animation: false,
                    }
                ]
            });
        })
    }

    const fetchAll = (params, shouldLoadAllServices = true) => {
        setIsLoading(true);

        let getServicesPromise = new Promise(resolve => resolve([]));
        if(shouldLoadAllServices) {
            getServicesPromise = serviceClient.getAll(0, 1000, {
                dateType,
                relativeUnit,
                relativeValue,
                startAt: startAt.unix(),
                endAt: endAt.unix()
            }).then(({data}) => {
                setServiceOptions(data.map((service) => ({value: service.name, label: service.name})));
            });
        }

        Promise.all([
            getServicesPromise,
            fetchData(params),
            fetchDataTransaction(params)
        ]).then(() => {
            setIsLoading(false)
        })
    }

    const refresh = () => {
        const params = {
            relativeValue,
            relativeUnit,
            dateType,
            startAt,
            endAt,
            service_name: service_name,
            transaction_name: transactionName
        };

        fetchAll(params);
    }

    const handleOnClick = (transaction_name) => {
        setTransactionName(transaction_name);

        searchParams.set("transactionName", encodeURIComponent(transaction_name))
        setSearchParams(searchParams);

        const params = {
            relativeValue,
            relativeUnit,
            dateType,
            startAt,
            endAt,
            transaction_name: encodeURIComponent(transaction_name)
        };

        fetchDataTransaction(params)
    }

    const setConfigurationHandler = ({
                                         dateType,
                                         relativeUnit,
                                         relativeValue,
                                         startAt,
                                         endAt
                                     }) => {
        setDateType(dateType);
        setRelativeUnit(relativeUnit);
        setRelativeValue(relativeValue);
        setStartAt(startAt);
        setEndAt(endAt);

        searchParams.set("dateType", dateType);
        searchParams.set("relativeUnit", relativeUnit);
        searchParams.set("relativeValue", relativeValue);
        searchParams.set("startAt", startAt.unix());
        searchParams.set("endAt", endAt.unix());
        setSearchParams(searchParams);

        fetchAll({
            dateType,
            relativeUnit,
            relativeValue,
            startAt,
            endAt
        })
    }

    const handleSelectService = (selectedOption) => {
        setTransactionName('*');
        setSelectedService(selectedOption);
        setServiceName(selectedOption.value);
        searchParams.set("transactionName", "*")
        searchParams.set("serviceName", selectedOption.value);
        setSearchParams(searchParams);

        const params = {
            relativeValue,
            relativeUnit,
            dateType,
            startAt,
            endAt,
            service_name: selectedOption.value,
            transaction_name: '*'
        };

        fetchAll(params);
    }

    function formatQuantity(numero) {
        if(!numero) return 0;

        const mil = 1000;
        const milhao = 1000000;
        const bilhao = 1000000000;

        if (numero < mil) {
            return numero.toString();
        } else if (numero < milhao) {
            return `${(numero / mil).toFixed(2)}k`
        } else if (numero < bilhao) {
            return `${(numero / milhao).toFixed(2)}Mi`
        } else {
            return `${(numero / bilhao).toFixed(2)}Bi`
        }
    };

    const getTextColorByScore = (apdex_score) => {
        if(!apdex_score || apdex_score < 0.75) return 'text-danger';
        if(apdex_score < 0.85) return 'text-warning';
        if(apdex_score < 0.94) return 'text-info';
        return 'text-primary';
    }

  useEffect(() => {
      serviceClient.getAll(0, 1000, { dateType, relativeUnit, relativeValue, startAt, endAt } ).then(({ data }) => {
          setServiceOptions(data.map((service) => ({ value: service.name, label: service.name })));

          let _service = null;
          if(service_name) {
              _service = data.find((service) => service.name === service_name);
          }
          if(!_service) _service = data[0];

          if(_service?.name) {
              setServiceName(_service?.name || "");
              setSelectedService({ value: _service.name, label: _service.name });

              if (!searchParams.get("serviceName") || searchParams.get("serviceName") !== _service.name) {
                  searchParams.set("serviceName", _service.name)
              }

              if (!searchParams.get("transactionName") || searchParams.get("transactionName") !== transactionName) {
                  searchParams.set("transactionName", transactionName)
              }

              setSearchParams(searchParams);

              const params = {
                  relativeValue,
                  relativeUnit,
                  dateType,
                  startAt,
                  endAt,
                  service_name: _service.name,
                  transaction_name: transactionName
              };

              fetchAll(params, false);
          }
      });

  }, []);

    return (
        <>
            <Row>
                <Col sm={12}>
                    <h1><i className={"fa-solid fa-chart-simple"}></i> Serviços</h1>
                </Col>
            </Row>
            <Row style={{marginBottom: '20px'}}>
                <Col sm={6}>
                    <div style={{width: '400px', display: 'inline-block'}} id={"select-service"}>
                        <Select value={selectedService}
                                classNamePrefix="select"
                                options={serviceOptions} onChange={handleSelectService} />
                    </div>

                    {isLoading && (<InfinitySpin
                        visible={true}
                        width={"100"}
                        color="#4fa94d"
                        ariaLabel="infinity-spin-loading"
                    />)}
                </Col>
                <Col sm={6} className={"d-flex justify-content-end align-items-end"}>
                    <Button onClick={refresh} className={"btn-outline-primary shadow-none"} style={{"background": "#fefefe", height: '48px', position: 'relative', top: '-1px'}}><i className="fa-solid fa-refresh"></i></Button>
                    <DefaultDatePicker setConfiguration={setConfigurationHandler} _dateType={dateType} _startAt={startAt} _endAt={endAt} _relativeUnit={relativeUnit} _relativeValue={relativeValue}/>
                </Col>
            </Row>

            {!service_name && (<>
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <p>Nenhum serviço encontrado.</p>
                                <Button className={"btn-outline-primary"} onClick={() => toast.error("TODO: precisa ser implementado!")}>Adicionar Novo Serviço</Button>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>)}

            {(rows.length === 0) && (<>
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardBody>
                                <p>Nenhuma métrica encontrada para o período selecionado</p>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>)}

            {(service_name && rows.length > 0) && (
                <>
                    <Row>
                        <Col md={3} style={{"paddingRight": 0}}>
                            <Card>
                                <CardHeader>
                                    <span style={{fontSize: '12px', fontWeight: 'normal'}}>Transações</span>
                                </CardHeader>
                                <CardBody style={{padding: 0}}>
                                    <div id={"table-transactions"}>
                                        {rows.map((transaction, index) => (
                                            <div className={transactionName === transaction.transaction_name ? "active-row" : ""}
                                                onClick={() => handleOnClick(transaction.transaction_name)}>
                                                <span style={{
                                                    background: 'none',
                                                    boxShadow: 'none',
                                                    padding: 0,
                                                    fontWeight: 'normal',
                                                    textAlign: 'left'
                                                }}>{transaction.transaction_name}</span>

                                                <small style={{display: 'block'}} title={"Total de Execuções"}>Exec. {formatQuantity(transaction.total_executions)} / Apdex <apdex class={getTextColorByScore(transaction.apdex_score)}>{transaction.apdex_score.toFixed(2)}</apdex> / <latenciatitle title={"Latência"}>Latên.</latenciatitle> <latency class={getTextColorByScore(transaction.apdex_score)}>{formatTime(transaction.p50)}</latency> / Erros <error_rate>{(transaction.error_rate * 100).toFixed(1)}%</error_rate></small>
                                            </div>
                                        ))}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={9} id={"apis-charts"}>
                            <Row>
                                <Col sm={3}>
                                    <Card>
                                        <CardHeader>
                                            Apdex (0.5)
                                        </CardHeader>
                                        <CardBody style={{textAlign: "center"}}>
                                            <h1 className={getTextColorByScore(rows.find(r => r.transaction_name === transactionName)?.apdex_score)}>{rows.find(r => r.transaction_name === transactionName)?.apdex_score?.toFixed(4)}</h1>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={3}>
                                    <Card>
                                        <CardHeader>
                                            Latência Média
                                        </CardHeader>
                                        <CardBody style={{textAlign: "center"}}>
                                            <h1 className={getTextColorByScore(rows.find(r => r.transaction_name === transactionName)?.apdex_score)}>{formatTime(rows.find(r => r.transaction_name === transactionName)?.avg_duration)}</h1>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={3}>
                                    <Card>
                                        <CardHeader>
                                            Total de Execuções
                                        </CardHeader>
                                        <CardBody style={{textAlign: "center"}}>
                                            <h1 style={{color: '#333'}}>{formatQuantity(rows.find(r => r.transaction_name === transactionName)?.total_executions)}</h1>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={3}>
                                    <Card>
                                        <CardHeader>
                                            % de Erros
                                        </CardHeader>
                                        <CardBody style={{textAlign: "center"}}>
                                            <h1 style={{color: getColorError(rows.find(r => r.transaction_name === transactionName)?.error_rate)}}>{(rows.find(r => r.transaction_name === transactionName)?.error_rate * 100)?.toFixed(2)}%</h1>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <HorizontalSpace/>

                            <Row>
                                <Col md={6}>
                                    <Card>
                                        <CardHeader>
                                            Latência
                                        </CardHeader>
                                        <CardBody>
                                            <Line data={reportExecutionMetricsLatency} options={optionsLatency}/>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col md={6}>
                                    <Card>
                                        <CardHeader>
                                            Execuções por Minuto
                                        </CardHeader>
                                        <CardBody>
                                            <Line data={reportExecutionMetrics} options={options}/>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <HorizontalSpace/>

                            <Row>
                                <Col md={6}>
                                    <Card>
                                        <CardHeader>
                                            Apdex (0.5s)
                                        </CardHeader>
                                        <CardBody>
                                            <Line data={reportExecutionMetricsApdex} options={optionsApdex}/>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col md={6}>
                                    <Card>
                                        <CardHeader>
                                            Erros %
                                        </CardHeader>
                                        <CardBody>
                                            <Line data={reportExecutionMetricsErrors} options={optionsErrors}/>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <HorizontalSpace/>

                            <Row>
                                <Col md={6}>
                                    <Card>
                                        <CardHeader>
                                            HTTP Status
                                        </CardHeader>
                                        <CardBody>
                                            <Line data={httpStatusMetrics} options={optionsHttpStatus}/>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            )}
        </>
    )
}

export default ServiceView;
