/*eslint-disable*/
import { NavLink as NavLinkRRD, Link, useLocation } from "react-router-dom";

// reactstrap components
import { Collapse, Navbar, NavItem, NavLink, Nav, Button } from "reactstrap";
import { Divider } from "antd";
import AuthClient from "../../apis/auth";
import { useState } from "react";

var ps;

const checkIncludes = (paths) => {
  const href = window.location.href;
  for (let i = 0; i < paths.length; i++) {
    if (href.includes(paths[i])) return true;
  }
  return false;
};

const isMenuActive = (path) => {
  return window.location.pathname.startsWith(path) ? 'active' : ''
}

const Sidebar = () => {
  function logout() {
    new AuthClient().logout();
    window.location.href = "/";
  }

  const getCurrentTracePath = () => {
    return "/traces?" + document.location.href.split("?")[1];
  };

  const [account_id] = useState(localStorage.getItem("current_account_id"));
  const [current_account] = useState(
    JSON.parse(localStorage.getItem("current_account") || "{}")
  );


  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
      style={{
        paddingTop: "30px",
        borderRight: "1px solid #ccc",
        boxShadow: "none !important",
      }}
    >
      {account_id && (
        <Collapse navbar isOpen={true} style={{ paddingTop: 0 }}>
          <Nav navbar>
            <NavItem key={1} className={isMenuActive("/apm")}>
              <NavLink
                to={"#"}
                style={{ cursor: "pointer" }}
              >
                <i className="fa-solid fa-cubes"></i>
                <span>APM</span>
              </NavLink>
              <Nav style={{display: 'block'}}>
                <NavItem key={1}>
                  <NavLink to={"/apm/services"} tag={NavLinkRRD} className={isMenuActive("/apis")}>
                    <i className={"fa-solid fa-chart-simple"}></i>
                    <span>Serviços</span>
                  </NavLink>
                </NavItem>

                {/*<NavItem key={2}>*/}
                {/*<NavLink to={"/traces"} tag={NavLinkRRD}>*/}
                {/*<i className="fa-solid fa-user-secret"></i>*/}
                {/*<span>Traces</span>*/}
                {/*</NavLink>*/}
                {/*</NavItem>*/}
              </Nav>
            </NavItem>

            <NavItem key={2} className={isMenuActive("/monitoring")}>
              <NavLink
                to={"#"}
                style={{ cursor: "pointer" }}
              >
                <i className="fa-brands fa-watchman-monitoring"></i>
                <span>Monitoramento</span>
              </NavLink>

              <Nav style={{display: 'block'}}>
                <NavItem key={3}>
                  <NavLink to={"/monitoring/channels"} tag={NavLinkRRD}>
                    <i className="fa-solid fa-envelopes-bulk"></i>
                    <span>Canais</span>
                  </NavLink>
                </NavItem>

                <NavItem key={4}>
                  <NavLink to={"/monitoring/alarms"} tag={NavLinkRRD}>
                    <i className="fa-solid fa-bell"></i>
                    <span>Alarmes</span>
                  </NavLink>
                </NavItem>

                <NavItem key={5}>
                  <NavLink to={"/monitoring/incidents"} tag={NavLinkRRD}>
                    <i className="fa-solid fa-triangle-exclamation"></i>
                    <span>Incidentes</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </NavItem>

            {current_account?.role === "admin" && (
              <NavItem key={3} className={isMenuActive("/administration")}>
                <NavLink
                  to={"#"}
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa-solid fa-gears"></i>
                  <span>Administração</span>
                </NavLink>

                <Nav style={{display: 'block'}}>
                  <NavItem key={1}>
                    <NavLink to={"/administration/api_keys"} tag={NavLinkRRD}>
                      <i className={"fa-solid fa-key"}></i>
                      <span>Api Keys</span>
                    </NavLink>
                  </NavItem>

                  <NavItem key={2}>
                    <NavLink to={"/administration/users"} tag={NavLinkRRD}>
                      <i className="fa-solid fa-users"></i>
                      <span>Usuários</span>
                    </NavLink>
                  </NavItem>

                  <NavItem key={3}>
                    <NavLink
                      to={"/administration/consumptions"}
                      tag={NavLinkRRD}
                    >
                      <i className="fa-solid fa-hard-drive"></i>
                      <span>Consumo</span>
                    </NavLink>
                  </NavItem>

                  <NavItem key={4}>
                    <NavLink to={"/administration/billings"} tag={NavLinkRRD}>
                      <i className="fa-solid fa-money-bills"></i>
                      <span>Cobranças</span>
                    </NavLink>
                  </NavItem>

                  <NavItem key={4}>
                    <NavLink to={"/administration/credits"} tag={NavLinkRRD}>
                      <i className="fa-solid fa-coins"></i>
                      <span>Créditos</span>
                    </NavLink>
                  </NavItem>

                  {/*<NavItem key={5}>*/}
                  {/*  <NavLink to={"/administration/payments"} tag={NavLinkRRD}>*/}
                  {/*    <i className="fa-solid fa-credit-card"></i>*/}
                  {/*    <span>Pagamento</span>*/}
                  {/*  </NavLink>*/}
                  {/*</NavItem>*/}
                </Nav>
              </NavItem>
            )}
          </Nav>
        </Collapse>
      )}

      <Divider />

      <div style={{ margin: "0 20px" }}>
        <Button
          className={"btn-outline-dark btn-expand btn-small"}
          onClick={logout}
        >
          Sair
        </Button>
      </div>
    </Navbar>
  );
};

export default Sidebar;
