import ReportsClient from "../../../apis/reports";
import {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Bar} from "react-chartjs-2";
import dayjs from "dayjs";
import {DatePicker} from "antd";
import Select from "react-select";
import {useSearchParams} from "react-router-dom";
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const reports = new ReportsClient();

function formatNumber(numero) {
    if(!numero || numero === '00') return 0;

    const mil = 1000;
    const milhao = 1000000;
    const bilhao = 1000000000;

    if (numero < mil) {
        return numero.toString().replace(".", ",")
    } else if (numero < milhao) {
        return `${(numero / mil).toFixed(2)}k`.replace(".", ",")
    } else if (numero < bilhao) {
        return `${(numero / milhao).toFixed(2)}Mi`.replace(".", ",")
    } else {
        return `${(numero / bilhao).toFixed(2)}Bi`.replace(".", ",")
    }
}

function getBytesUnit(value) {
    if(value < 1024) {
        return 'B'
    }

    if(value < 1024 * 1024) {
        return 'KB'
    }

    if(value < 1024 * 1024 * 1024) {
        return 'MB'
    }

    if(value < 1024 * 1024 * 1024 * 1024) {
        return 'GB'
    }

    if(value < 1024 * 1024 * 1024 * 1024 * 1024) {
        return 'TB'
    }

    return 'PB'
}

// KB, MB, GB, TB
function formatBytes(total_bytes, unit){
    if(total_bytes === 0) {
        return 0
    }

    if(!unit) {
        unit = getBytesUnit(total_bytes)
    }

    if(unit === 'B') {
        return total_bytes
    }

    if(unit === 'KB') {
        return (total_bytes / 1024).toFixed(2)
    }

    if(unit === 'MB') {
        return (total_bytes / (1024 * 1024)).toFixed(2)
    }

    if(unit === 'GB') {
        return (total_bytes / (1024 * 1024 * 1024)).toFixed(2)
    }

    if(unit === 'TB') {
        return (total_bytes / (1024 * 1024 * 1024 * 1024)).toFixed(2)
    }

    if(unit === 'PB') {
        return (total_bytes / (1024 * 1024 * 1024 * 1024 * 1024)).toFixed(2)
    }
}

const ConsumptionIndex = () => {
    let [searchParams, setSearchParams] = useSearchParams();

    const [currentDate, setCurrentDate] = useState(dayjs(searchParams.get("currentDate") || new Date()));

    const [totalDiskUsage, setTotalDiskUsage] = useState(0);
    const [totalSpans, setTotalSpans] = useState(0);
    const [totalChecks, setTotalChecks] = useState(0);

    const [consuptions, setConsuptions] = useState({
        fill: true,
        labels: [],
        datasets: []
    });
    const [consumptionOptions, setConsumptionOptions] = useState({
        responsive: false,
        maintainAspectRatio: false,
        scales: {},
        plugins: {},
        interaction: {}
    });

    const onChangeDate = (date) => {
        if(!date) return;

        setCurrentDate(date);

        searchParams.set("currentDate", date.format("YYYY-MM-DD"));
        setSearchParams(searchParams);

        fetchAll({ date })
    }

    const fetchAll = async ({ date }) => {
        reports.getConsumption({
            year: date.year(),
            month: date.month() + 1
        }).then((data) => {
            const allBytes = data.map(d => parseInt(d['total_bytes']))
            const bytesUnit = getBytesUnit(allBytes.max())
            const allBytesFormatted = allBytes.map(b => formatBytes(b, bytesUnit))
            const allChecks = data.map(d => parseInt(d['total_checkers']))

            setConsumptionOptions({
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                    mode: 'index',
                    intersect: false
                },
                scales: {
                    'yLeft': {
                        type: 'linear',
                        position: 'left',
                        // min: parseInt((data['total_count'].min() * 0.9).toFixed(0)),
                        // max: parseInt((data['total_count'].max() * 1.1).toFixed(0)),
                        grid: {
                            display: true
                        },
                        ticks: {
                            callback: function (value, index, values) {
                                return value + bytesUnit;
                            }
                        }
                    },
                    'yRight': {
                        type: 'linear',
                        position: 'right',
                        // min: parseInt((data['total_count'].min() * 0.9).toFixed(0)),
                        // max: parseInt((data['total_count'].max() * 1.1).toFixed(0)),
                        grid: {
                            display: false
                        }
                    },
                    x: {
                        display: false,
                        ticks: {
                            display: false //this will remove only the label
                        }
                    }

                },
                plugins: {
                    legend: {
                        position: 'bottom'
                    },
                    tooltip: {
                        callbacks: {
                            label: function(tooltipItems) {
                                const text = tooltipItems.datasetIndex === 0 ? tooltipItems.raw + bytesUnit : formatNumber(tooltipItems.raw)
                                return tooltipItems.dataset.label + ": " + text
                            }
                        }
                    },
                }
            })

            setConsuptions({
                labels: data.map(d => d['dt']),
                datasets: [
                    {
                        label: 'Ingestão de Dados',
                        data: allBytesFormatted,
                        borderColor: 'rgb(45,206,137)',
                        yAxisID: 'yLeft',
                        pointRadius: 0,
                        fill: true,
                        backgroundColor: 'rgba(45,206,137, 0.1)',
                        borderWidth: 1,
                        animation: false
                    },
                    {
                        label: 'Monitoramento',
                        data: allChecks,
                        borderColor: 'rgb(245,54,92)',
                        yAxisID: 'yRight',
                        pointRadius: 0,
                        fill: true,
                        backgroundColor: 'rgba(245,54,92, 0.1)',
                        borderWidth: 1,
                        animation: false
                    }
                ]
            });

            const total_bytes = allBytes.reduce((a, b) => a + b, 0);
            const total_bytes_unit = getBytesUnit(total_bytes);
            const total_bytes_formatted = formatBytes(total_bytes, total_bytes_unit);

            const total_spans = data.map(d => parseInt(d['total_spans'])).reduce((a, b) => a + b, 0);

            setTotalDiskUsage(total_bytes_formatted.toString() + total_bytes_unit);
            setTotalSpans(formatNumber(total_spans));
            setTotalChecks(formatNumber(allChecks.reduce((a, b) => a + b, 0)))
        });
    }

    useEffect(() => {
        if(!searchParams.get("currentDate") && currentDate) {
            searchParams.set("currentDate", currentDate.format("YYYY-MM-DD"));
        }

        setSearchParams(searchParams)

        fetchAll({ date: currentDate })
    }, []);

    return (<>
        <Row>
            <Col sm={12}>
                <h1><i className="fa-solid fa-hard-drive"></i> Consumo</h1>
            </Col>
        </Row>

        <Card>
            <CardHeader>
                <Row>
                    <Col sm={4}>
                        <span>Período: </span>
                        <DatePicker
                            picker={"month"}
                            format={"MM-YYYY"}
                            size={"large"}
                            onChange={onChangeDate}
                            defaultValue={currentDate}
                            value={currentDate}
                            separator={"/"}
                            style={{
                                height: '48px',
                                textAlign: 'center',
                                width: '150px',
                                border: '#fafafa'
                            }} />
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col sm={3}>
                        <h3>Ingestão de Dados</h3>
                        <p>
                            {totalDiskUsage?.toString().replace(".", ",")} <small>(10 GB grátis todo mês)</small>
                            <small style={{display: 'block', fontSize: '10px'}}>Total de dados ingeridos</small>
                        </p>

                        <h3>Total de Checkers</h3>
                        <p>
                            {totalChecks} <small>(43k grátis todo mês)</small>
                            <small style={{display: 'block', fontSize: '10px'}}>Total de Checkers executados</small>
                        </p>

                    </Col>

                    <Col sm={9}>
                        <div style={{
                            height: '400px',
                            width: '100%'
                        }}>
                            <Bar data={consuptions} options={consumptionOptions}/>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    </>)
}

export default ConsumptionIndex;