import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Navigate } from "react-router-dom";

import {
  GuardConfigProvider,
  GuardedRoute,
  GuardedRoutes,
  GuardProvider,
} from "react-router-guarded-routes";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-toastify/dist/ReactToastify.css";

import "assets/scss/argon-dashboard-react.scss";

import "react-accessible-accordion/dist/fancy-example.css";

// custom css
import "assets/css/custom/table-bootstrap.css";
import "assets/css/custom/form-error-message.css";
import "assets/css/custom/accordion.css";


import { ToastContainer } from "react-toastify";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import PageLoading from "components/commom/PageLoading.js";

import { isAuthenticated } from "variables/auth.js";
import PolicyLayout from "./layouts/PolicyLayout";

const logGuard = (to, from, next) => {
  if(!isAuthenticated() && !to.location.pathname.startsWith("/auth")) {
    return next("/auth/login");
  }

  next();
};

const guards = [logGuard];

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
    <BrowserRouter>
      <GuardConfigProvider>
        <GuardProvider fallback={<PageLoading />} guards={guards}>
          <GuardedRoutes>
            <GuardedRoute path="/policy" element={<PolicyLayout />} />
            <GuardedRoute path="/auth/*" element={<AuthLayout />} />
            <GuardedRoute path="/*" element={<AdminLayout />} />
          </GuardedRoutes>
        </GuardProvider>
      </GuardConfigProvider>
    </BrowserRouter>
  </>
);
