import ApiClient from "./api";

class CreditApi extends ApiClient {
  constructor() {
    super();
  }

  getAll(page = 0, limit = 10, query = {}) {
    try {
      page = page === 0 ? 0 : page - 1;
      let queryString = new URLSearchParams(query).toString();
      return this.get(
        `api/v2/administration/credits?page=${page}&per_page=${limit}&count=true&${queryString}`
      );
    } catch (error) {
      return [];
    }
  }
}

export default CreditApi;
