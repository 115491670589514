import ApiClient from "./api";

class TransactionAttributesClient extends ApiClient {
  constructor() {
    super();
  }

  getMetrics(queryParams = {}) {
    let queryString = new URLSearchParams(queryParams).toString();
    return this.get(`/api/v2/transaction_attributes/metrics?${queryString}`)
  }
}

export default TransactionAttributesClient;
