import ApiClient from "./api";

class MeClient extends ApiClient {
  constructor() {
    super();
  }

  async getInfo() {
    try {
      const data = await this.get("api/users/me");
      if (data) {
        localStorage.setItem('name', data.email);
      }
      return data
    } catch (error) {
      console.error(error)
    }
  }

  async getAccounts() {
    return this.get("api/users/accounts")
  }

  async getCurrentAccount() {
    return this.get("api/users/current_account")
  }

  async getCurrentUserAccount() {
    return this.get("api/users/current_user_account")
  }

  async switchAccount(account_id) {
    const data = await this.post("api/users/switch_account", { account_id });

    if (!data.token) {
      return false;
    }

    const token = data.token;
    localStorage.setItem("token", token);
    const account = await this.getCurrentAccount();
    localStorage.setItem("current_account", JSON.stringify(account));

    return true;
  }
}

export default MeClient;
